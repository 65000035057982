@font-face 
	font-family: 'social-share-kit' 
	src: url('../fonts/social-share-kit.eot') 
	src: url('../fonts/social-share-kit.eot?#iefix') format('embedded-opentype'), url('../fonts/social-share-kit.woff') format('woff'), url('../fonts/social-share-kit.ttf') format('truetype'), url('../fonts/social-share-kit.svg#social-share-kit') format('svg') 
	font-weight: normal 
	font-style: normal 

.ssk-group 
	display: flex 
	justify-content: flex-end

	+bp($bp-small)
		justify-content: flex-start
.ssk 
	background-color: $baseTxt
	color: white 
	display: inline-block 
	font-size: 22px 
	line-height: 1px 
	margin-right: 2px 
	margin-bottom: 2px 
	padding: 7px 
	text-align: center 
	text-decoration: none 
	transition: background-color .1s
	cursor: pointer 
	margin-left: 9px 
	border-radius: 5px
	filter: grayscale(1)
	display: flex 
	align-items: center 
	justify-content: center
	width: 63px 
	height: 63px
	&:before 
		display: inline-block 
		font-family: "social-share-kit" !important 
		font-style: normal !important 
		font-weight: normal !important 
		font-variant: normal !important 
		text-transform: none !important 
		speak: none 
		line-height: 1 
		font-size: 38px
		-webkit-font-smoothing: antialiased
		-moz-osx-font-smoothing: grayscale 
	&:last-child 
		margin-right: 0 
	&:hover 
		background-color: #424242
		filter: grayscale(0)
	&.ssk-icon 
		color: #757575 
		padding: 2px 
		font-size: 24px
		&:hover 
			color: #424242
	&.ssk-text 
		overflow: hidden 
		font-size: 17px 
		line-height: normal 
		padding-right: 10px 
.ssk-facebook 
	&:before 
		content: "a" 
		text-indent: 4px 
		margin-right: -4px 
	&:hover 
		background-color: #2d4373 
		background-color: #2d4373 
	&.ssk-icon 
		color: #3B5998 
		&:hover 
			color: #2d4373 
		&:before 
			text-indent: 0 
			margin-right: 0 
.ssk-twitter 
	&:before 
		content: "b" 
	&:hover 
		background-color: #0c85d0 
		background-color: #0c85d0 
	&.ssk-icon 
		color: #1DA1F2 
		&:hover 
			color: #0c85d0 


.ssk-email 
	background-color: $baseTxt
	&:before 
		content: "d" 
		top: -1px 
		position: relative 
		font-size: 30px
	&:hover 
		background-color: #5b5b5b 
		background-color: #5b5b5b 
	&.ssk-icon
		color: #757575 
		&:hover 
			color: #5b5b5b 

.ssk-linkedin 
	&:before 
		content: "g" 
	&:hover 
		background-color: #156791 
		background-color: #156791 
	&.ssk-icon 
		color: #1c87bd 
		&:hover 
			color: #156791 



.ssk-facebook 
	&:hover
		background-color: #3B5998 

+bp($bp-large)
	.ssk 
		width: 40px 
		height: 40px

		&:before 
			font-size: 25px

+bp($bp-small)
	.ssk
		margin-left: 0  
		margin-right: 10px


