@font-face 
  font-family: 'Circula'
  src: url('../fonts/Circula-Medium.woff') format('woff')
  font-weight: 500

@font-face 
  font-family: 'CircularStd'
  src: url('../fonts/CircularStd-Black.woff') format('woff')
  font-weight: 900

@font-face 
  font-family: 'CircularStd Book'
  src: url('../fonts/CircularStd-Book.woff') format('woff')
  font-weight: 500

@font-face 
  font-family: 'CircularStd'
  src: url('../fonts/CircularStd-Bold.woff') format('woff')
  font-weight: bold

@font-face 
  font-family: 'CircularStd'
  src: url('../fonts/CircularStd-Medium.woff') format('woff')
  font-weight: 500

@font-face
  font-family: 'Quicksand'
  src: url('../fonts/Quicksand-Bold.woff') format('woff')
  font-weight: bold

@font-face
  font-family: 'Quicksand'
  src: url('../fonts/Quicksand-Regular.woff') format('woff')
  font-weight: normal





