.bizness
	padding: 120px 0 130px
	&--bottom 
		padding-bottom: 65px

	p 
		font-size: rem(30px)
		font-family: $secondaryFont
		margin: 45px 0

	&__app 
		margin-top: 20px


	+bp($bp-large)
		p 
			font-size: rem(25px)
			margin: 25px 0
			max-width: 90%

	+bp($bp-tablet)
		padding: 90px 0

		&--bottom 
			padding-bottom: 50px

		p 
			font-size: rem(20px)
			margin: 24px 0

	+bp($bp-small)
		padding: 50px 0
		&--bottom 
			padding-bottom: 40px
		p 
			font-size: rem(17px)
			margin: 20px 0

.scool 
	padding: 140px 0 110px 
	background-color: $gray1

	&--b 
		padding-top: 90px
		padding-bottom: 0

	&--top 
		padding-top: 50px
	
	&--irs 
		padding-top: 0
		padding-bottom: 120px

	&__subtitle
		margin-bottom: 90px
		margin-top: 0 

	&__title
		margin-bottom: 5px
	
	&__cen 
		text-align: center
		margin-top: 15px

	&-list 
		padding: 95px 67px 
		margin: 0
		border-radius: 58px
		box-shadow: 0px 9px 9px #00000029
		background-color: $gray5

		&__item
			line-height: 110%
			position: relative
			font-family: $secondaryFont
			margin-bottom: 45px 
			font-size: rem(25px)
			padding-left: 90px
			&:last-child
				margin-bottom: 0

			&:before 
				content: ''
				width: 36px 
				height: 36px 
				position: absolute 
				left: 0 
				top: -6px 
				+foil 
				background-image: url('../images/it.png')
		&--default
			padding: 0 !important
			background-color: transparent
			.scool-list__item 
				padding-left: 38px 
				margin-bottom: 30px 
				&:last-child 
					margin-bottom: 0
				&:before 
					width: 28px
					height: 30px
					top: -2px

					+bp($bp-small2)
						top: 13px

	&__top 
		margin-bottom: 80px
		font-family: $secondaryFont

	&__btn 
		margin-top: 15px 
	
	&-list
		&--white 
			// background-color: $white
			padding-top: 45px 
			padding-bottom: 45px

			.scool-list__item 
				&:before 
					display: none 

			img 
				width: 23px 
				height: 23px
				position: absolute 
				left: 0 
				top: -1px

	+bp($bp-large)
		padding-top: 110px
		&--top 
			padding-top: 40px
		&--b 
			padding-top: 90px
		&--irs 
			padding-top: 0

		&__subtitle 
			margin-bottom: 50px

		&-list 
			padding: 35px 50px
			
			
		

	+bp($bp-tablet)
		padding: 90px 0

		&--b 
			padding-bottom: 0

		&--top 
			padding-top: 35px
		
		&--irs 
			padding-top: 0

		&__subtitle
			margin-bottom: 40px

		&-list 
			padding: 50px 30px
			&__item 
				padding-left: 50px 
				font-size: rem(16px)
				margin-bottom: 30px
				&:before 
					width: 35px 
					height: 35px
					top: 50%
					transform: translateY(-60%)

	+bp($bp-small)
		padding: 50px 0

		&--top 
			padding-top: 25px
		
		&--b 
			padding-bottom: 0
		
		&--irs 
			padding-top: 0

		&-list 
			padding: 25px 
			border-radius: 20px

			&__item 
				font-size: rem(15px)
				margin-bottom: 15px

		&__top 
			margin-bottom: 35px
		
		&__subtitle
			margin-bottom: 20px

	

.types 
	background-color: $gray1
	padding-bottom: 90px
	&--top 
		padding-top: 60px
	&--accel 
		padding-top: 80px
	&__list 
		display: flex 
		flex-wrap: wrap
		justify-content: center
		margin: 0 -30px 

	&__item 
		width: calc(100%/3)
		padding: 0 30px
		margin-bottom: 75px

	+bp($bp-large)
		&--top 
			padding-top: 0
		&__list 
			margin: 0 -22px
		&__item 
			padding: 0 22px
			margin-bottom: 40px

	+bp($bp-large2)
		&__item 
			width: 50%

	+bp($bp-tablet)
		padding-bottom: 70px
		&__list 
			margin: 0 
			display: block 
		&__item 
			width: 100% 
			padding: 0

	+bp($bp-small)
		padding-bottom: 30px

		&--accel 
			padding-top: 50px

.why 
	background-color: $gray1
	padding-bottom: 150px

	&__list 
		display: grid 
		grid-template-columns: repeat(2, 1fr)
		grid-gap: 60px 
		counter-reset: item
	&__item 
		color: $white 
		background-color: $baseTxt 
		border-radius: 34px 
		text-align: center 
		padding: 27px 50px 45px 
		&:before
			content: counters(item, ".") " "
			counter-increment: item
			display: inline-flex 
			align-items: center 
			justify-content: center 
			width: 105px 
			text-align: center
			height: 97px
			background-color: $orange 
			border-radius: 27px 
			color: $white
			font-size: rem(75px)
	&__name
		margin: 33px 0 0

	&__part 
		display: flex
		flex-wrap: wrap
		justify-content: center
		border-radius: 34px 
		background-color: $white
		padding: 80px 40px
		box-shadow: $shadowBlock2

		&--block
			display: block

		&--default
			padding-left: 0 
			padding-right: 0 
			padding-bottom: 0
			border: none
			box-shadow: none
			background-color: transparent
		
		&--numeric
			counter-reset: item 
			li 
				&:before 
					content: counters(item, ".") " " !important
					counter-increment: item

		&--line
			padding-top: 0

	&-unit
		flex: 0 0 calc(33.33% - 50px)
		background-color: $white 
		border-radius: 34px
		box-shadow: $shadowBlock2
		padding: 0 15px 30px
		text-align: center
		background-color: $gray1
		margin-bottom: 70px
		margin-left: 25px
		margin-right: 25px
		&:before
			content: url("../images/icons/i-1.png")
			display: inline-flex 
			align-items: center 
			justify-content: center 
			width: 173px
			text-align: center
			height: 156px
			background-color: $purple 
			border-radius: 27px 
			color: $white
			font-size: rem(105px)
			box-shadow: $shadowBlock
			transform: translateY(-30px)
			
		
		&:nth-child(2)
			&:before
				content: url("../images/icons/i-2.png")
		
		&:nth-child(3)
			&:before
				content: url("../images/icons/i-3.png")

		&__text 
			padding: 35px 35px 0

			a 
				color: $purple 
				&:hover 
					color: $orange
			p 
				text-align: left
				position: relative
				padding-left: 25px 
				margin-left: 8px
				&:before
					content: ''
					display: inline-block
					width: 12px 
					height: 12px 
					border-radius: 50%
					background-color: $baseTxt
					margin-right: 10px
					position: absolute 
					left: 0 
					top: 8px

				+bp($bp-small)
					margin-left: 14px
					padding-left: 20px 
					&:before 
						width: 9px 
						height: 9px 
						top: 4px


			&--only 
				padding-top: 0
				margin-bottom: 0
		&--small 
			.why-unit__text 
				font-family: $secondaryFont

			+bp($bp-large2, 'min')
				.why-unit__text 
					font-size: rem(18px)
					padding-left: 5px 
					padding-right: 5px 
				.btn 
					font-size: rem(30px)

		&--orange 
			&:before 
				background-color: $orange

		&--btn-l 
			padding-left: 38px
			padding-right: 38px
			padding-bottom: 38px
			.btn 
				min-height: 124px
				font-size: rem(24px)
				line-height: 1.2
				font-family: $secondaryFont

			.why-unit__text
				display: none

		&--line 
			background: transparent
			box-shadow: none
			border-radius: 0
			flex: 100% 
			text-align: left
			display: flex
			align-items: flex-start
			margin: 0 0 40px 
			padding: 0
			&:last-child
				margin-bottom: 0
			&:before 
				flex-shrink: 0 
				transform: none
			h6 
				padding-top: 7px
				padding-left: 45px

	&__btn 
		margin-top: 80px

	&__text-large 
		font-size: rem(35px)
		line-height: 126%

	&__title
		max-width: 1200px


	+bp($bp-large)
		padding-bottom: 100px

		&__text-large 
			font-size: rem(24px)

		&__list 
			grid-gap: 30px

		&__part 
			padding: 50px 20px
			&--default
				padding-left: 0 
				padding-right: 0 
				padding-bottom: 0
			&--line
				padding: 0
			

		&-unit

			&__text 
				font-size: rem(20px)

			&:before 
				width: 120px 
				height: 112px
				font-size: rem(80px)

		&__name 
			font-size: rem(25px)

	+bp($bp-large2)
		&-unit
			flex: 0 0 calc(50% - 30px)
			margin-left: 15px 
			margin-right: 15px

			&--line
				flex: 100% 
				margin-left: 0 
				margin-right: 0

	+bp($bp-medium)
		&-unit 
			&__text 
				padding: 25px 0 0
				&--only 
					padding-top: 0
		&__text-large 
			font-size: rem(24px)
	+bp($bp-tablet)
		padding-bottom: 80px
		&__item 
			&:before 
				width: 75px
				font-size: rem(50px)
				height: 70px
				border-radius: 10px
		&-unit 
			&:before 
				font-size: rem(70px)
				border-radius: 15px

			&__text 
				font-size: rem(14px)

		&__btn 
			margin-top: 40px

		&__name 
			font-size: rem(14px)


	+bp($bp-small2)
		padding-bottom: 60px
		&__part 
			display: block
			&--line
				padding-top: 0

		&__item 
			padding: 20px 10px
			border-radius: 15px

		&__name
			margin-top: 15px

		&__list 
			grid-gap: 10px

		&__item 
			&:before 
				width: 55px
				font-size: rem(30px)
				height: 50px
				border-radius: 10px

		&-unit 
			margin-left: 0 
			margin-right: 0
			&:before 
				font-size: rem(50px)
				border-radius: 15px
				transform: scale(0.7) translateY(-60px)
			&:last-child 
				margin-bottom: 0

			&--btn-l 
				padding-left: 20px
				padding-right: 20px
				padding-bottom: 25px
				.btn 
					min-height: 60px
					font-size: rem(16px)
			.btn 
				margin-top: -30px

			&--line
				margin-bottom: 25px
				.why-unit__text
					padding: 5px 0 0 15px
					margin-bottom: 0
		&__part 
			padding: 40px 20px
			&--default
				padding-left: 0 
				padding-right: 0 
				padding-bottom: 0
			&--line
				padding-top: 0

		&__btn 
			margin-top: 27px
		&__text-large 
			font-size: rem(18px)

.special-wrapper
	background-color: $gray1
	padding-bottom: 200px
	padding-top: 80px
	+bp($bp-large)
		padding-bottom: 150px
		padding-top: 0
	+bp($bp-medium)
		padding: 30px 0 120px
	+bp($bp-small)
		padding: 0px 0 70px
.special 
	background-color: $baseTxt 
	color: $white 
	padding: 120px 107px 208px 
	box-shadow: $shadowBlock2
	border-radius: $radBlockSmall

	&__top 
		margin-bottom: 100px
		display: flex 
		justify-content: space-between 
		align-items: center
		max-width: 1532px

	&-block 
		color: $baseTxt
		border-radius: $radBlockSmall
		padding: 70px 50px 45px 
		background-color: $gray1
		box-shadow: $shadowBlock2
		max-width: 1532px
		&__name
			margin-bottom: 60px

	&__title 
		margin: 0 25px 0 0
		&:after 
			content: ''
			display: inline-block
			width: 98px 
			height: 98px
			margin-left: 4px
			+foil 
			background-image: url('../images/alert.png')

	+bp(1800px)
		&__btn 
			font-size: 2vw

	+bp(1600px)
		&__title
			font-size: 5vw

	+bp($bp-large)
		padding: 50px 40px
		&__top	
			margin-bottom: 50px

		&__title 
			&:after 
				width: 72px 
				height: 72px

		&-block 
			padding: 50px 30px 45px
			.blockquote-small,
			.list
				margin-bottom: 45px

			&__name
				margin-bottom: 30px

	+bp($bp-medium)
		padding: 40px 20px
		&__top 
			display: block 
		&__title 
			font-size: 6.5vw 
			margin: 0 0 25px 0
			&:after 
				width: 50px 
				height: 50px
		&__btn 
			font-size: 17px

		&-block 
			padding: 30px 15px
			.blockquote-small,
			.list
				margin-bottom: 30px

			&__name
				margin-bottom: 20px

	+bp($bp-small)
		&__btn 
			font-size: rem(13px)
			width: 100%
		&__top 
			margin-bottom: 30px
		&__title 
			&:after 
				width: 30px 
				height: 30px
		.list 
			margin-bottom: 20px

.tags 
	max-width: 1350px
	display: flex 
	flex-wrap: wrap
	&--full 
		max-width: 100%
	&__item 
		display: inline-block 
		margin-bottom: 42px 
		margin-right: 30px
		box-shadow: $shadowBlock 
		background-color: $white
		padding: 23px 27px
		border-radius: 58px
		color: $baseTxt
		&--center 
			text-align: center
			border-radius: 76px
			padding-left: 62px 
			padding-right: 62px
	&__item-name
		margin: 0
		&--large 
			margin-bottom: 10px
	&__item-text 
		font-size: rem(20px)

	+bp($bp-large)
		max-width: 950px
		&--full 
			max-width: 100%
		&__item
			margin-bottom: 20px 
			margin-right: 15px 
			padding: 17px 20px
			&--center 
				padding: 40px 25px
				margin-bottom: 35px 
				margin-right: 20px 
		&__item-name
			font-size: rem(20px)
			&--large 
				margin-bottom: 3px 
				line-height: 1
				font-size: rem(30px)
		&__item-text
			display: block 
			font-size: rem(18px)
			max-width: 600px

	+bp($bp-small)
		&__item 
			padding: 12px 
			margin-right: 0px 
			margin-bottom: 8px
			display: block
			width: 100%
			text-align: center
			&--center 
				border-radius: 30px
		&__item-name
			font-size: rem(14px)
			&--large 
				font-size: rem(20px)
		&__item-text 
			font-size: rem(13px)


.list 
	margin-bottom: 60px
	&__item 
		position: relative 
		padding-left: 50px
		margin-bottom: 20px 

		&:last-child 
			margin-bottom: 0 

		h6 
			margin: 0

		&:before 
			content: ''
			width: 24px
			height: 24px 
			border-radius: 50%
			background-color: $baseTxt
			position: absolute 
			left: 0 
			top: 1px


	+bp($bp-small)
		margin-bottom: 30px 
		&__item 
			margin-bottom: 10px

.person 
	padding: 40px 39px 26px 206px 
	max-width: 738px 
	width: 100% 
	margin: 30px auto 0
	border-radius: 58px 
	box-shadow: 0px 9px 4.5px 0px rgba(0, 0, 0, 0.16)
	background-color: $white
	position: relative
	font-family: $secondaryFont

	&__photo 
		width: 141px 
		height: 141px 
		border-radius: 50%
		overflow: hidden
		+center('v')
		left: 34px
	
	&__desc 
		p 
			font-size: rem(24px)
			margin-top: 0 
	
	&__name
		color: $orange 
		font-size: rem(28px)
		margin-bottom: 0 
	
	&__tx 
		font-size: rem(22px)
	
	+bp($bp-small)
		padding: 20px 
		border-radius: 20px 

		&__photo 
			position: static 
			transform: none 
			margin: 0 auto 20px
		
		&__desc 
			p
				font-size: rem(18px)
		
		&__name
			font-size: rem(24px)
		
		&__tx 
			font-size: rem(16px)
	

