.stage
	display: grid 
	grid-template-columns: repeat(4, 1fr)
	grid-gap: 33px

	+bp($bp-large2)
		grid-template-columns: repeat(2, 1fr)

	+bp($bp-small2)
		grid-gap: 15px


.stage-block 
	background-color: $white 
	padding: 80px 15px 90px 
	border-radius: $radBlockSmall
	box-shadow: $shadowBlock2 
	text-align: center 

	&__image 
		height: 122px
		margin-bottom: 65px
		display: flex 
		align-items: center 
		justify-content: center
		img 
			max-height: 100%

	&__name
		margin-bottom: 45px
		margin-top: 0 
		font-size: rem(35px)

	&__text 
		max-width: 310px 
		font-size: rem(25px)
		margin: 0 auto 
		line-height: 126%

	+bp($bp-large)
		padding: 40px 15px 50px 

		&__image
			margin-bottom: 50px 
			height: 84px
		&__name
			margin-bottom: 25px
			font-size: rem(25px)

		&__text 
			font-size: rem(20px)

	+bp($bp-small2)
		padding: 25px 10px
		border-radius: 20px
		&__text 
			font-size: rem(16px)

		&__image
			height: 60px
			margin-bottom: 20px

		&__name
			font-size: rem(22px)
			margin-bottom: 15px
	


