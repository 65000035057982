.gen 
	display: block
	padding-bottom: 45px  
	margin-bottom: 45px
	margin-top: 45px
	border-bottom: 1px solid $gray5
	font-size: rem(18px)
	line-height: 166%
	font-family: $secondaryFont

	&-pdf 
		display: inline-flex 
		align-items: center 
		margin-top: 25px
		color: $baseTxt !important

		&:hover 
			color: $purple !important
			.gen-pdf__icon 
				transform: translateY(-4px)

		&__icon 
			+flex_center_b
			font-size: rem(16px)
			line-height: 1 
			width: 47px
			height: 28px
			border-radius: 10px
			margin-right: 8px
			background-color: $orange 
			color: $white
			font-weight: 400 !important
			transition: 0.35s ease

		&__pdf-name
			font-weight: 400 !important
			margin-left: 4px
	&__part
		display: flex
		padding-bottom: 10px 

	&__unit 
		width: 217px 
		margin-right: 52px 
		flex-shrink: 0

	&__unit-black 
		border-radius: 15px 
		background-color: $baseTxt
		padding: 11px
		margin-bottom: 10px 
		color: $white

	&__unit-title  
		font-size: inherit !important
		line-height: inherit !important
		font-family: $baseFont
		margin: 0 !important
		font-weight: 400 !important
		&:last-child 
			margin-bottom: 0

	strong 
		font-family: $baseFont

	&__category 
		border-radius: 9px 
		background-color: $gray5
		padding: 11px
		margin-bottom: 10px

	&__size 
		display: inline-block
		background-color: $purple 
		margin-bottom: 10px 
		color: $white 
		padding: 0 11px 
		border-radius: 10px
		line-height: 155%

	&__link
		color: $purple 
		border-bottom: 1px solid $purple
		font-weight: 500 !important
		word-wrap: break-word
		transition: 0.5s

		&:hover 
			border-color: transparent

	&__data 
		strong 
			margin-right: 4px

	+bp($bp-small2)
		&__part 
			display: block
			padding-bottom: 0
		&__unit 
			width: 100% 
			margin-bottom: 20px
		&-pdf 
			flex-wrap: wrap
			margin-top: 20px
			&__pdf-name
				margin-top: 5px 
				margin-left: 0

	+bp($bp-small)
		font-size: rem(16px)
		line-height: 150%
		margin: 34px 0 
		padding-bottom: 30px


