// COLORS
$baseTxt: #212427
$black: #000000
$white: #ffffff
$orange: #FF6D00
$purple: #6809F9
$gray1: #EEEDED
$gray2: #7D7D7D
$gray3: #7C7C7C
$gray4: #7C7E80
$gray5: #CFCFCF
$gray6: #DEDEDE
$gray7: #707070
$gray8: #1C1717
$gray9: #D9D9D9


// FONTS
$baseFont: 'CircularStd', sans-serif
$secondaryFont: 'CircularStd Book', sans-serif
$decorFont: 'Quicksand', sans-serif
$baseRem: 100%


//shadow
$shadowBlock: 0px 10px 10px #00000029
$shadowHov: 0px 10px 30px rgba(255, 109, 0, 0.55)
$shadowHovPurple: 0px 10px 30px rgba(104, 9, 249, 0.55)
$shadowBlock2: 0px 10px 15px #00000029


//radius 
$radBlock: 62px
$radBlockSmall: 34px
$radBlockTab: 30px
$radBlockMob: 20px


//gutter
$baseGutter: 30px


//transition
$transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out, border-color 0.35s ease-in-out


// content width
$content-wrapper-width: 1746px
$content-wrapper-width-small: 1290px
$content-wrapper-gap: 67px
$column-gap: 20px


// Should use these
$bp-large: 1680px
$bp-large-md: 1400px
$bp-large2: 1150px
$bp-medium: 992px
$bp-tablet: 768px
$bp-small2: 650px
$bp-small: 576px


// TYPOGRAPHY
@import 'variables/typography-variables'

