.footer 
	background-color: $baseTxt 
	color: $white 
	padding: 100px 0 60px
	font-family: $secondaryFont 

	&__part 
		display: flex 
		justify-content: space-between

		&--top
			font-size: rem(20px) 
			align-items: flex-end 
			padding-bottom: 15px

		&--bottom 
			padding-top: 29px 
			border-top: 2px solid $gray4 
			font-size: rem(16px)
			align-items: center
	
	&__logo 
		transform: translateX(-5px) translateY(-5px)

		+bp($bp-small2)
			transform: none
			
	&__list 
		text-align: right 
		padding-bottom: 12px
	&__item 
		margin-bottom: 11px 
		margin-top: 0
		line-height: 1
		&:last-child
			margin-bottom: 0
	&__link 
		color: $white 
		transition: $transition 
		font-size: rem(20px)
		&:hover 
			color: $purple
		&--small
			margin-right: 28px
			font-size: rem(16px) 
			&:last-child
				margin-right: 0
	&__text 
		margin-bottom: 30px
		transform: translateY(12px)
		&:after 
			content: ''
			display: inline-block 
			vertical-align: middle
			margin-left: 5px
			width: 25px
			height: 25px
			+foil 
			background-image: url('../images/footer_flag.png')
			border-radius: 50%

	&__address 
		margin-bottom: 30px
		line-height: 1.2
		span 
			display: block
	&__title
		margin-bottom: 25px

	&__form
		width: 312px
		flex-shrink: 0
	&__column-left 
		padding-bottom: 22px

	&__column
		&--middle
			width: 400px
			margin-left: auto 
			margin-right: 13.5%
		&--right
			width: 170px 
			flex-shrink: 0

	+bp($bp-large)
		padding: 45px 0 56px
		&__column 
			&--middle
				margin-right: 8%
				width: 325px
		&__link 
			font-size: rem(16px)
			&--small 
				font-size: rem(14px)
		&__text 
			transform: none 
			margin-bottom: 20px
			&:after 
				width: 18px 
				height: 18px
		&__coop 
			font-size: rem(14px)


		&__part 
			&--bottom 
				padding-top: 25px
		&__item 
			margin-bottom: 16px

		&__title
			font-size: rem(16px)
			margin-bottom: 20px

		&__list 
			margin-top: -39px

		&__address 
			margin-bottom: 20px

		.form-field__input,
		.btn 
			height: 29px
			line-height: 29px
			font-size: rem(16px)


		&__form 
			max-width: 210px
			padding-bottom: 15px
			margin: 0 auto

		&__address,
		&__text 
			font-size: rem(16px)


	+bp($bp-large2)
		&__column 
			&--middle
				margin-right: 0%
				padding-right: 20px
		&__link 
			&--small
				margin-right: 10px
		&__coop 
			max-width: 200px
			line-height: 1.2

	+bp($bp-medium)
		&__part 
			&--bottom 
				flex-direction: column 

		&__half
			margin: 20px 0
			justify-content: center

		&__column 
			&--middle
				margin: 0 auto
				padding-right: 0
				width: auto
			&--right
				width: auto

		&__coop 
			max-width: 100%

		&__form
			width: 240px

		.social-links 
			transform-origin: 50% 50%

	+bp($bp-tablet)
		padding: 75px 0 20px

	+bp($bp-small2)
		text-align: center
		padding: 50px 0 30px
		&__part 
			font-size: rem(16px)
			&--top
				display: block
			&--bottom 
				font-size: rem(14px)
		&__list 
			display: flex 
			flex-wrap: wrap 
			justify-content: space-around
			margin-top: 30px
		&__item 
			padding: 0 10px 
		&__link 
			font-size: rem(16px)
			&--small 
				font-size: rem(14px)
		&__text 
			transform: none 
			margin-top: 20px
		&__text, 
		&__address 
			margin-bottom: 17px 
		&__title
			margin-bottom: 10px
		&__form
			width: 100%


	

