// H1
$h1-fs: rem(300px)
$h1-lh: 100%

// H1 1280
$h1-fs-1280: rem(110px)
$h1-lh-1280: 100%

// H1 768
$h1-fs-768: rem(100px)
$h1-lh-768: 100%

// H1 480
$h1-fs-480: rem(60px)
$h1-lh-480: 100%



// H2
$h2-fs: rem(60px)
$h2-lh: 105%

// H2 1280 
$h2-fs-1280: rem(60px)
$h2-lh-1280: 105%

// H2 768 
$h2-fs-768: rem(60px)
$h2-lh-768: 105%

// H2 480
$h2-fs-480: rem(40px)
$h2-lh-480: 105%



// H3
$h3-fs: rem(52px)
$h3-lh: 126%

// H3 1280 
$h3-fs-1280: rem(38px)
$h3-lh-1280: 126%

// H3 768 
$h3-fs-768: rem(28px)
$h3-lh-768: 126%

// H3 480
$h3-fs-480: rem(24px)
$h3-lh-480: 110%



// H4
$h4-fs: rem(41px)
$h4-lh: 126%

// H4 1280 
$h4-fs-1280: rem(41px)
$h4-lh-1280: 126%

// H4 768 
$h4-fs-768: rem(24px)
$h4-lh-768: 126%

// H4 480
$h4-fs-480: rem(21px)
$h4-lh-480: 120%



// H5
$h5-fs: rem(40px)
$h5-lh: 126%

// H5 1280 
$h5-fs-1280: rem(35px)
$h5-lh-1280: 126%

// H5 768 
$h5-fs-768: rem(24px)
$h5-lh-768: 126%

// H5 480
$h5-fs-480: rem(16px)
$h5-lh-480: 110%



// H6
$h6-fs: rem(30px)
$h6-lh: 126%

// H6 1280
$h6-fs-1280: rem(25px)
$h6-lh-1280: 126%

// H6 768 
$h6-fs-768: rem(18px)
$h6-lh-768: 126%

// H6 480
$h6-fs-480: rem(16px)
$h6-lh-480: 120%



// P
$p-fs: rem(25px)
$p-lh: 128%

// P 1280
$p-fs-1280: rem(20px)
$p-lh-1280: 128%

// P 768
$p-fs-768: rem(16px)
$p-lh-768: 128%

// P 480
$p-fs-480: rem(15px)
$p-lh-480: 128%



// SMALL
$small-fs: rem(18px)
$small-lh: 120%

// SMALL 1280
$small-fs-1280: rem(13px)
$small-lh-1280: 120%

// SMALL 768
$small-fs-768: rem(13px)
$small-lh-768: 120%

// SMALL 480
$small-fs-480: rem(13px)
$small-lh-480: 120%
