.sim 
  padding-bottom: 40px
  &__line 
    margin-bottom: 40px 
    text-align: center 
    font-family: $secondaryFont
    h4,h2 
      margin: 0
    &--left 
      text-align: left
    &--large 
      margin-bottom: 60px
    &--bot 
      margin-bottom: 0
    .person 
      text-align: left 
    &--title 
      margin-top: 70px 
    &--top 
      margin-top: 40px

  &__btn 
    max-width: 689px 
    width: 100% 
    margin: 0 auto
  
  &__unit 
    max-width: 639px 
    margin: 0 auto 60px

    h2 
      font-size: rem(40px)
    
    .data-list li
      font-family: $secondaryFont
      font-weight: 400
      text-align: left
  
  &__step 
    display: flex 
    flex-direction: column 
    align-items: center
  
  &__tag 
    font-size: rem(28px)
    display: inline-flex 
    font-weight: 500 
    font-family: $baseFont
    align-items: center 
    padding: 10px 36px
    border-radius: 76px 
    box-shadow: 0px 9px 4.5px 0px rgba(0, 0, 0, 0.16)
    margin-bottom: 40px
    background-color: $white

    span 
      margin-left: 22px
  
  &__title 
    font-size: rem(30px)
    margin-top: 0
    margin-bottom: 15px
  
  &__title-top 
    margin-bottom: 40px
  
  &__part 
    display: flex 
    flex-wrap: wrap
    justify-content: center 
    margin: 0 -20px
    padding-bottom: 20px
  
  &__item 
    width: 50%
    padding: 0 20px 
    margin-bottom: 50px

    +bp($bp-tablet)
      margin-bottom: 28px
  
  &__block 
    padding: 35px 55px 40px 
    border-radius: 50px 
    box-shadow: 0px 9px 4.5px 0px rgba(0, 0, 0, 0.16) 
    background-color: $white
    height: 100%
  
  &__text 
    font-size: rem(18px)
    margin-top: 14px
    font-family: $secondaryFont
    line-height: 130%
  
  &__partners 
    margin-top: 70px 
   
  &__partners-title 
    margin-bottom: 20px
    text-align: center
  
  &__top 
    margin-top: 90px
  
  &__subtitle
    margin: 0
  
  +bp($bp-medium)
    &__item 
      width: 100% 
      padding: 0 
      margin-bottom: 35px
    
    &__part 
      margin: 0
    
    &__block 
      padding: 25px 20px 
      border-radius: 30px
    
    &__title
      margin-bottom: 15px
  
  +bp($bp-small)
    &__btn
      font-size: rem(22px)
    
    &__line 
      margin-bottom: 30px
      &--top 
        margin-top: 20px 
      &--title 
        margin-top: 40px
    
    &__tag 
      font-size: rem(22px)
      margin-bottom: 20px

      span 
        margin-left: 12px 
        img 
          width: 22px
    &__unit 
      margin-bottom: 35px
      h2 
        font-size: rem(26px)
    
    
    &__title 
      font-size: rem(20px)
      margin-bottom: 15px
    
    &__title-top
      margin-bottom: 20px 
    
    &__top 
      margin-top: 50px 
    
    &__partners-title 
      text-align: left
    
    &__text 
      font-size: rem(15px)
      font-family: $baseFont

.webinar-inner 
  &__btn
     text-align: center 
     .btn
        margin-bottom: 60px 
        max-width: 916px 
        width: 100%
        font-size: rem(40px)

        &--small-w 
          max-width: 787px 
          font-size: rem(30px)
          margin-bottom: 0
          margin-top: 40px
        
        &--small-w-2 
          max-width: 727px 
          margin-top: 80px
        
        +bp($bp-medium)
          font-size: rem(20px)
          marbgin-bottom: 15px

          &--small-w
            margin-top: 25px
  
  .btn--small-f
    font-size: rem(25px)
  
  &__part 
    display: flex 
    align-items: center 
  
  &__logo 
    pointer-events: none 
    margin-left: 120px
  
  &__tx 
    margin: 0 60px 
    font-size: rem(30px)
  
  &__part-unit 
    display: flex 
    flex-direction: row-reverse
    align-items: center 

    .webinar-inner__logo 
      margin: 0
  
  +bp($bp-medium)
    &__logo 
      margin-left: 20px
    
  
  +bp(850px)
    &__tx 
      margin: 0 32px
      font-size: rem(25px)
  
  +bp($bp-tablet)
    &__logo 
      font-size: rem(28px)
  
  +bp(700px)
    &__tx 
      margin: 0 12px
  
  +bp(450px)
    &__logo
      font-size: rem(24px)
    
    &__tx 
      font-size: rem(22px)
      margin: 0 8px 0 12px
  
  +bp(370px)
    &__part 
       flex-direction: column-reverse
       align-items: flex-start
    &__logo 
      margin-left: 0 
      margin-bottom: 30px
    
    &__part-unit
      flex-direction: row
      margin-bottom: 30px 
    
    &__tx 
      font-size: rem(24px)
      margin: 0 15px
      transform: translateY(2px)

.top--webinar 
   padding: 50px 0

   