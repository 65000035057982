.animation-bar-1
  position: relative
  display: block
  font-size: 16px
  line-height: 16px
  margin-bottom: 25px

  span
    position: relative
    display: inline-block
    vertical-align: middle
    height: 20px
    border-radius: 10px 0 0 10px
    overflow: hidden
    background-size: 100%
    background: #F8AA70 linear-gradient(to bottom, #FB8832, #FF6D00)
    animation: progress-anim-1 7.5s infinite linear

    &:after
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      content: ""
      background-image: linear-gradient(45deg, #ffffff 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, #ffffff 50%, #ffffff 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0))
      background-size: 30px 30px
      opacity: 0.3
      animation: progress-anim-1-after 0.5s infinite linear

@-webkit-keyframes progress-anim-1
  0%
    width: 0%

  60%
    width: 60% 

  80%
    width: 80%

  100%
    width: 100%

@-webkit-keyframes progress-anim-1-after
  0%
    background-position: 0 100%

  100%
    background-position: 30px 100%
