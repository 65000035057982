.article-bowl 
	display: block 
	border-radius: $radBlockSmall
	background-color: $gray5
	box-shadow: $shadowBlock
	padding: 27px
	height: 100%
	&__image 
		margin-bottom: 20px 
		img 
			margin: 0 !important
	&__name 
		margin: 20px 0 0



	+bp($bp-small)
		padding: 15px 
		border-radius: 20px

		.tag 
			transform: scale(0.6)
			transform-origin: 0 50%

		&__name 
			margin-top: 4px

		&__image 
			margin-bottom: 5px