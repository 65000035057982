.box-unit 
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-start

	.desc-dark 	
		flex-grow: 1
		display: flex 
		align-items: flex-start

	+bp($bp-medium)
		margin-bottom: 30px 
		&:last-child 
			margin-bottom: 0
		.desc-dark 
			margin-top: 15px

	+bp($bp-small)
		margin-bottom: 20px
		