.tag 
	display: inline-block
	color: $white 
	background-color: $purple 
	padding: 17px 30px 
	font-size: rem(22px)
	border-radius: 25px
	box-shadow: $shadowBlock
	min-width: 195px 
	text-align: center
	

	&--md 
		font-size: rem(24px)

	&--large 
		font-size: rem(48px)
		border-radius: 28px
		padding: 20px 35px
	
	&--large-mid 
		font-size: rem(30px)

	&--large-lg
		height: auto 
		font-size: rem(65px)

	&--gray 
		color: $baseTxt 
		background-color: $gray5 
		box-shadow: $shadowBlock 
		text-align: left

	&--m-b 
		margin-bottom: 45px

	&--small-xs 
		padding: 7px 10px
		min-width: 0
		&:hover 
			color: $white !important
		h3 
			margin: 0 !important
			padding:  0 !important
			font-size: inherit !important
	&--full 
		width: 100%

	&--top 
		margin-top: 35px

	&--h 
		padding-top: 20px
		padding-bottom: 20px
		line-height: 1.2

	&--dark
		background-color: $baseTxt 
		box-shadow: none 
	&--m 
		margin-right: 20px 
		margin-bottom: 20px
		min-width: 0 !important
		text-align: left

	+bp($bp-large) 
		font-size: 18px 
		padding: 12px 17px
		min-width: 160px
		&--large 
			font-size: rem(30px) !important
			padding: 14px 35px
		&--large-lg
			font-size: rem(45px) !important
			padding: 12px 35px

		&--small 
			font-size: rem(16px)
		&--small-xs 
			padding: 7px 10px
			min-width: 0
		&--h 
			padding-top: 16px
			padding-bottom: 16px

	+bp($bp-large2)
		&--large 
			font-size: rem(25px) !important
		&--large-lg
			font-size: rem(30px) !important
		&--m-b 
			margin-bottom: 30px

	+bp($bp-small)
		font-size: rem(16px) !important
		padding: 11px 11px
		border-radius: 30px
		&--small-xs 
			padding: 7px 10px
			min-width: 0
		&--top 
			margin-top: 15px
		&--m 
			margin-right: 10px 
			margin-bottom: 10px