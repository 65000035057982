.btn
	display: inline-flex
	flex-wrap: wrap
	align-items: center
	justify-content: center
	font-family: $baseFont
	color: $baseTxt
	font-size: rem(18px)
	font-weight: 500
	text-align: center
	cursor: pointer
	padding: 0 30px
	border-bottom: none
	height: rem(50px)
	position: relative	
	transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out
	line-height: 1


	&[disabled],
	&.disabled
		opacity: .5
		pointer-events: none

	&--small 
		width: 100%
		height: 41px
		border-radius: 13px 
		border: 1px solid $white 
		color: $white
		font-size: rem(20px)

		&:hover 
			border-color: $orange
			background-color: $orange

	&--purple
		height: 120px 
		background-color: $purple
		box-shadow: $shadowBlock2
		color: $white 
		border-radius: 34px
		font-size: rem(65px)
		&:hover 
			background-color: $orange

	&--no-link 
		cursor: default 
		pointer-events: none
		&:hover 
			background-color: $purple

	&--dark
		box-shadow: 0px 9px 9px #00000029
		background-color: $baseTxt
		border-radius: 55px
		&:hover 
			background-color: $purple 
			box-shadow: $shadowHovPurple

	&--orange 
		background-color: $orange 
		&:hover 
			background-color: $purple

	&--gray 
		color: $baseTxt 
		background-color: $gray5
		font-size: rem(55px)
		&:hover 
			background-color: $purple
			color: $white


	&--medium-f
		font-size: rem(50px)

	&--full 
		width: 100%

	&--middle
		height: rem(70px)
		font-size: rem(40px)
		border-radius: 45px

	&--small-f
		font-size: rem(45px)

	&--middle-lg
		height: rem(98px)
		border-radius: 26px

	&--small-r 
		border-radius: 30px

	&--services 
		border-radius: 30px
		font-family: $secondaryFont

	&--services-xs-f 
		font-size: rem(40px)


	&--white 
		background-color: $white 
		color: $baseTxt

		&:hover 
			color: $white
			background-color: $purple
	
	&--black 
		background-color: $baseTxt 
		color: $white

	&--title 
		height: 110px 
		border-radius: 25px 
		box-shadow: 0px 10px 7.5px rgba(0, 0, 0, 0.160784)
		color: $white 
		background-color: $purple
		cursor: default
		font-size: rem(35px)
		width: 100%
		font-family: $secondaryFont
		font-weight: 500
		line-height: 1.2

	&--title-orange
		background-color: $orange

	&--title-large
		font-size: rem(50px)
		height: 165px
		font-family: $baseFont

	&--purple-mid 
		background-color: $purple 
		border-color: $purple 
		height: 95px 
		font-size: rem(50px)
		border-radius: 50px
		width: 100% 
		color: $white

	&--xs 
		font-size: rem(50px)
		padding: 0 55px 
		border-radius: $radBlock

	&--mini 
		border: 1px solid $white 
		padding: 10px 15px 
		font-size: rem(18px)
		color: $white 
		border-radius: 12px
		&:hover 
			border-color: $purple 
			background-color: $purple 
			color: $white !important

	&--types-min 
		font-size: rem(28px)

	&--fs30
		font-size: rem(30px)

	&--dark-small 
		background-color: $baseTxt 
		color: $white 
		padding: 10px 35px 
		border-radius: 30px
		box-shadow: 0px 9px 4.5px rgba(0, 0, 0, 0.160784)
		font-size: rem(25px)
		font-weight: 500
		&:hover 
			background-color: $purple 
			box-shadow: $shadowHovPurple
	+bp(1750px)
		&--types-small
			font-size: rem(40px)

	+bp(1600px)
		&--types-small
			font-size: rem(36px)
		&--types-min 
			font-size: rem(18px)



	+bp($bp-large)
		&--purple 
			height: 86px
			font-size: rem(50px)
			border-radius: 34px

		&--small-f
			font-size: rem(40px)

		&--middle 
			height: rem(53px)
			font-size: rem(40px)

		&--purple-mid
			height: rem(66px)
			font-size: rem(35px)

		&--small-r 
			min-height: rem(59px)
			font-size: rem(33px)
			height: auto

		&--types 
			height: auto 
			min-height: 71px
			padding: 10px 25px 
			font-size: rem(35px)

		&--gray 
			height: rem(94px)
			font-size: rem(40px)

		&--white 
			height: rem(64px)
			font-size: rem(30px)

		&--types-small
			font-size: rem(25px)
		
		&--types-mid
			font-size: rem(30px)

		&--title-large
			font-size: rem(50px)
			height: 165px
		&--types-min 
			font-size: rem(18px)
		&--fs30
			font-size: rem(30px)

		&--services-xs-f 
			font-size: rem(30px)

	+bp(1230px)
		&--types-small
			font-size: rem(21px)
		&--title 
			font-size: rem(27px)
			height: 90px


	// +bp($bp-large2)
	// 	&--purple 
	// 		font-size: rem(35px)
	// 		height: 80px
	// 		border-radius: 25px
	// 	&--small-f
	// 		font-size: rem(35px)

	// 	&--middle
	// 		font-size: rem(35px)

	// 	&--mobile 
	// 		font-size: rem(35px)

	// 	&--large-f
	// 		font-size: rem(35px)
	// 	&--purple-mid
	// 		height: 80px


	+bp($bp-medium)
		&--purple
			height: 80px 
			font-size: rem(27px)
			border-radius: 25px
		&--middle 
			height: 40px 
			font-size: rem(20px)

		&--middle-lg
			height: rem(80px)

		&--types-small
			font-size: rem(22px)
		&--title
			height: 50px
			font-size: rem(25px)
		&--types 
			height: auto

	+bp($bp-small)
		&--purple
			height: 60px 
			font-size: rem(22px)
			border-radius: 20px
		&--middle 
			height: 35px 
			font-size: rem(18px)
		&--middle-lg
			height: rem(50px)

		&--purple-mid 
			height: 50px 
			font-size: rem(20px)
		&--small-r 
			height: rem(44px)
			font-size: rem(21px)
		&--title
			height: 44px
			font-size: rem(21px)
		&--types 
			height: auto



