.philosophy 
	text-align: center 
	max-width: 341px
	width: 100%

	&__image
		height: 173px
		+flex_center_b
		margin-bottom: 50px 
		img 
			max-height: 100%

	&__title
		text-transform: uppercase

	+bp($bp-large)
		&__image
			height: 119px
			margin-bottom: 30px
		&__text 
			max-width: 272px 
			margin: 0 auto

	+bp($bp-medium)
		display: flex 
		align-items: center
		max-width: 100% 
		margin-bottom: 25px 
		text-align: left
		&:last-child
			margin-bottom: 0

		&__image
			width: 130px 
			height: 130px
			text-align: center 
			margin-right: 22px 
			flex-shrink: 0
			margin-bottom: 0

		&__title
			margin-bottom: 10px
			line-height: 1

		&__text 
			max-width: 100%
			p 
				margin-top: 0
	+bp($bp-small)
		display: block 
		text-align: center 

		&__image 
			width: 80px
			margin: 0 auto 20px
			height: auto