.header 
	display: block
	width: 100% 
	position: absolute 
	left: 0
	padding: 43px 0
	z-index: 5

	&__line 
		display: flex 
		align-items: flex-end
		justify-content: space-between

	&__mobile-close
		display: none

	&__btn 
		font-size: rem(18px)
		height: 42px 
		border-radius: 20px 
		display: flex 
		align-items: center
		padding: 0 22px
		background-color: $white
		cursor: pointer
		-webkit-appearance: none
		appearance: none
		color: $baseTxt !important

		+bp($bp-small)
			font-size: rem(15px)
			height: 37px 
			padding: 0 19px
			border-radius: 20px
			white-space: nowrap 
	
	&__lk 
		position: relative
		cursor: pointer
		&:hover 
			.header__lk-list 
				opacity: 1 
				pointer-events: auto
			.header__btn 
				border-bottom-left-radius: 0
				border-bottom-right-radius: 0
		
		+bp(360px)
			transform: scale(0.8)
			transform-origin: 100% 50%
	
	&__lk-list 
		position: absolute 
		left: 0
		top: 34px
		width: 100% 
		background-color: $white 
		border-radius: 0 0 20px 20px
		padding: 0px 22px 12px
		opacity: 0 
		pointer-events: none
		transition: 0.3s opacity
		+bp($bp-small)
			padding: 0 19px
			top: 30px

	&__lk-item
		line-height: 110% 
		margin: 18px 0

		+bp($bp-small)
			margin: 14px 0

	&__lk-link 
		font-size: rem(18px)
		transition: color 0.3s
		&:hover 
			color: $purple
		+bp($bp-small)
			font-size: rem(15px)


	+bp($bp-large2)
		padding: 30px 0

	+bp($bp-large-md)
		padding: 3.2vw 0
		.logo	
			font-size: 3.5vw



	+bp($bp-medium)
		padding: 10px 0
		position: fixed
		background-color: $gray1
		&.is-fixed
			top: 0 !important

		&__line
			align-items: center

		.logo 
			font-size: rem(49px)

		&__mobile-close 
			fill: $white
			position: absolute
			z-index: 12
			left: 20px
			top: 12px
			cursor: pointer
			&.is-active
				display: block

	+bp($bp-tablet)
		&__mobile-close
			top: 1px


	+bp($bp-small)
		padding: 8px 0

		.logo 
			font-size: rem(28px)
	
	+bp(360px)
		.logo 
			font-size: rem(25px)


.logo 
	display: inline-block
	font-family: $decorFont
	color: $gray3
	font-size: rem(49px)
	font-weight: bold
	letter-spacing: -2px
	position: relative
	z-index: 1

	&:hover 
		animation: shake 150ms 3 linear

	&__mark 
		color: $purple 

	&--white 
		color: $white 
		font-size: rem(65px)
		.logo__mark 
			color: $white

	+bp($bp-large)
		&--white
			font-size: rem(52px)

	+bp($bp-large2)
		font-size: rem(39px)
		&--whitef
			font-size: rem(50px)

	+bp($bp-medium)
		font-size: rem(49px)	

		&.is-active
			color: $white 
			transition: color 0.2s linear
			transition-delay: 0.2s
			.logo__mark
				color: $white
				transition: color 0.2s linear
				transition-delay: 0.2s

	+bp($bp-small)
		font-size: rem(28px)

	@keyframes shake
		0%
			transform: translate(3px, 0)
		50%
			transform: translate(-3px, 0)
		100%
			transform: translate(0, 0)

.nav 
	&__list 
		display: flex 
		align-items: center
		margin: 0
	&__item 
		padding: 0 2px 
		margin: 0
		position: relative
		&:after 
			display: none 
		&:last-child
			padding-right: 0


		&--has-dropdown
			position: relative
			padding: 0 20px
			&:after 
				content: ''
				border: solid black
				border-width: 0 2px 2px 0
				border-radius: 0 0 2px 0
				display: inline-block
				vertical-align: top
				margin-top: 11px
				padding: 3px 
				margin-left: 5px 
				transform: rotate(45deg)
				transition: 0.3s
				position: relative
				z-index: 4
			.nav__link 
				transition: none
				padding: 0 !important
				&:hover 
					background-color: transparent
			.nav__item 
				padding: 0 20px
			
				&--dropdown 
					margin-bottom: 18px

			+bp(993px, 'min')
				&:hover 
					&:after 
						border-color: $purple
					span.nav__link 
						color: $purple
					.nav__link 
						position: relative
						z-index: 4
					.nav__item-dropdown
						width: 160px
						transition: $transition
						opacity: 1 
						transform: scaleY(1) 
						pointer-events: auto
						z-index: 3

						+bp($bp-large)
							width: 140px

		&--dropdown 
			&:last-child 
				margin-bottom: 0

	&__link 
		font-size: rem(25px)
		line-height: 100%
		color: $baseTxt
		position: relative
		z-index: 2
		cursor: pointer
		padding: 18px 20px 
		border-radius: 12px
		background-color: transparent
		transition: background-color 0.3s ease

		&:hover
			color: $purple
			background-color: $white

		&.is-active
			color: $orange

			&:hover 
				color: $purple

	&__item-dropdown 
		position: absolute 
		top: -15px 
		left: 0 
		background-color: $white 
		border-radius: 12px 
		padding: 70px 0px 22px
		width: 100%
		opacity: 0 
		transform-origin: 0 0 
		transform: scaleY(0.75)
		pointer-events: none



	+bp($bp-large)
		&__link 
			font-size: rem(18px)
		&__item 
			&--has-dropdown
				&:after 
					margin-top: 7px
		&__item-dropdown
			padding-top: 60px

	+bp(1230px)
		&__link 
			font-size: 1.6vw
		.nav__link,
		.nav__item--has-dropdown,
		.nav__item--has-dropdown .nav__item
			padding-left: 1vw
			padding-right: 1vw

		&__item-dropdown 
			padding-top: 42px
			padding-bottom: 15px
		&__item 
			&--dropdown 
				margin-bottom: 0px
		.nav__item--has-dropdown:hover 
			.nav__item-dropdown
				width: 120px


	+bp($bp-medium)
		width: 100%
		overflow-y: auto
		position: fixed 
		height: 100vh
		top: 0 
		left: 0 
		+foil 
		background-color: $purple
		color: $white 
		display: block 
		padding-top: 0
		transform: translate3d(0,-105%,0)
		opacity: 0
		transition: transform 0.4s ease-in-out
		z-index: 2

		&.is-active
			opacity: 1
			transform: translate3d(0,0,0)


		&__list 
			display: block
			padding-left: 80px 
			padding-right: 80px
			padding-top: 120px 
			padding-bottom: 130px

		&__item 
			margin-bottom: 35px
			text-align: left
			&:last-child 
				margin-bottom: 0px
			&--has-dropdown
				&:after 
					display: none
			&--has-dropdown-mobile
				&:after 
					display: none
					// border-color: $white
					// margin-left: 12px 
					// margin-top: 9px
					// transform: rotate(-45deg)
				// &:hover 
				// 	&:after 
				// 		border-color: $orange
				&.is-active 
					.nav__item 
						transform: translate3d(0,0,0) !important
						opacity: 1 !important
					.nav__item-dropdown--mobile
						opacity: 1 
						pointer-events: auto

		&__link 
			font-size: rem(30px)
			color: $white 
			padding: 0
			background-color: transparent !important

			&:after 
				display: none
			&:hover
				color: $orange 

			&.is-active
				color: $orange
				&:hover 
					color: $white
		&__item-dropdown
			width: 100% !important 
			opacity: 1 
			transform: none 
			pointer-events: auto 
			background-color: transparent 
			padding: 0 
			position: static
			&--services
				display: none
			&--mobile
				position: fixed 
				top: 0 
				left: 0
				bottom: 0 
				right: 0
				padding: 120px 80px
				background-color: $purple
				z-index: 10 !important
				opacity: 0
				pointer-events: none

				.nav__link 
					z-index: 9
					&:hover 
						z-index: 9

				.nav__item 
					margin-top: 0  !important
					margin-bottom: 35px
					transform: translate3d(30px,0,0)
					opacity: 0
					transition: 0.4s

		&__item 
			padding-left: 0 !important
			padding-right: 0 !important
			&--dropdown 
				margin-bottom: 20px 
				&:last-child 
					margin-bottom: 20px
				&:first-child 
					margin-top: 20px
			
		&__link 
			padding-left: 0 !important
			padding-right: 0 !important



	+bp($bp-tablet)
		&__list 
			padding-left: 40px
			padding-right: 40px
		&__item-dropdown
			&--mobile
				padding: 75px 40px
				// .nav__item 
				// 	margin-bottom: 25px
		


	+bp($bp-small)
		&__list
			padding-top: 75px
		// &__item 
		// 	margin-bottom: 25px !important

		// &__link 
		// 	font-size: rem(23px)
				


.burger span,
.burger span:before,
.burger span:after 
	cursor: pointer 
	border-radius: 1px 
	height: 2px 
	width: 18px 
	background: $purple
	position: absolute 
	left: 0 
	top: 50% 
	display: block 
	content: '' 
	transition: all 0.5s ease-in-out
	border-radius: 5px


.burger 
	background: transparent 
	position: absolute
	right: 56px 
	height: 16px 
	width: 18px 
	cursor: pointer  
	display: none
	margin-right: -15px
	z-index: 3
	span 
		&:before 
			top: -5px 
		&:after 
			top: 5px 
	&.is-active 
		span 
			background-color: transparent 
			&:before 
				transform: rotate(135deg) 
				background-color: $white
			&:after 
				transform: rotate(-135deg)
				background-color: $white

	+bp($bp-medium)
		display: block

	+bp($bp-tablet)
		right: 34px

	+bp($bp-small)
		right: 34px



.burger.is-active span:before,
.burger.is-active span:after 
	top: 0 