.founder 
	padding: 150px 0 200px 
	background-color: $gray1
	&--how
		padding: 50px 0 200px 
	&-block 
		background-color: $baseTxt 
		color: $white 
		padding: 57px 57px 70px 
		border-radius: 81px
		box-shadow: $shadowBlock

	&__btn 
		margin-top: 70px

	&__subtitle 
		font-size: rem(36px)
		margin-bottom: 0

	+bp($bp-large)
		padding: 100px 0 110px

		&--how
			padding: 0px 0 150px

		&__subtitle 
			font-size: rem(30px)

	+bp($bp-medium)
		&-block 
			padding: 40px 25px 40px 
			border-radius: 40px

	+bp($bp-medium)
		padding: 50px 0 
		&--how
			padding: 0px 0 80px
		&-block 
			padding: 30px 20px
			border-radius: 20px

		&__subtitle 
			font-size: rem(24px)

		&__btn 
			margin-top: 40px

	+bp($bp-small)
		&__subtitle 
			font-size: rem(17px)
		&__btn 
			margin-top: 30px