.features 
	padding: 120px 0 180px
	background-color: $white

	&__title
		margin-top: 0
		margin-bottom: 130px

	&__list 
		padding-top: 50px

	&__services-part 
		max-width: 1200px
	
	&__top-text 
		margin-bottom: 50px
		margin-top: -15px
		p 
			margin-top: 0

	+bp($bp-large)
		padding: 80px 0 

		&__title
			margin-bottom: 50px

		&__services-part 
			max-width: 1000px

	+bp($bp-tablet)
		&__list 
			padding-top: 20px


	+bp($bp-small)
		padding: 50px 0 

		&__title
			margin-bottom: 30px


.data
	padding-bottom: 120px
	&--top 
		padding-top: 70px
	&__top 
		margin-top: 120px
		&--large
			margin-top: 170px
		&--bottom 
			padding-bottom: 80px
		&--small 
			margin-top: 70px
	&__faq 
		max-width: 802px
	&__block 
		max-width: 930px
		margin: 0 auto
	&__line
		margin-top: 30px
		text-align: center
	&__box 
		padding: 50px 70px 60px
		margin-bottom: 70px
		&--inline
			width: 50%
			margin-left: auto 
			margin-right: auto
	&__why-part 
		padding-bottom: 0px
		padding-top: 60px

	&__btn 
		margin-top: 60px
	&__text 
		text-align: center 
		margin-bottom: 15px
	&__title-w
		max-width: 867px
	&__title-w2
		max-width: 1087px
	&__box-desc
		margin-bottom: 0

	+bp($bp-large)
		padding-bottom: 90px
		&__title-w
			max-width: 767px
		&__title-w2
			max-width: 957px

	+bp($bp-large2)
		padding-bottom: 90px 

		&__top 
			margin-top: 80px
			&--bottom 
				padding-bottom: 30px
			&--small 
				margin-top: 50px

	+bp($bp-medium)
		&__box 
			padding: 30px
			margin-bottom: 30px 
			&--inline
				width: 100%
		&__btn 
			margin-top: 40px

	+bp($bp-small2)
		&--top 
			padding-top: 40px
		&__why-part 
			padding-bottom: 40px

		&__text 
			margin-top: 35px

		&__mobile-top 
			margin-top: 25px

	+bp($bp-small)
		padding-bottom: 50px 

		&__top 
			margin-top: 55px
			&--small
				margin-top: 40px
		&__btn 
			margin-top: 25px

		



.data-grid 
	display: grid
	grid-template-columns: repeat(2, 1fr)
	grid-gap: 20px

	&--list 
		counter-reset: a
		padding-top: 50px

	&--col-4
		grid-template-columns: repeat(4, 1fr)
	
	&--large 
		grid-gap: 80px
	
	&--icon 
			position: relative 
			&:before
				content: ''
				width: 24px 
				height: 24px 
				background-position: center center 
				background-repeat: no-repeat 
				background-size: cover 
				background-image: url('../images/plus.svg')
				+center('both')

			+bp($bp-tablet)
				display: grid !important
				grid-template-columns: 1fr
				grid-gap: 20px
				&:before
					display: none

	&-item 
		margin: 0 3px 50px

	&__column 
		display: flex 
		flex-direction: column
		.data-block 
			flex-grow: 1

	+bp(1250px)
		&--col-4
			grid-template-columns: repeat(2, 1fr)

	+bp($bp-tablet)
		display: block

		&__column 
			margin-bottom: 80px
			display: block 
			&:last-child
				margin-bottom: 0

	+bp($bp-small)
		&--list 
			padding-top: 20px

		&-item 
			margin: 0 0 55px

		&__column 
			margin-bottom: 40px

.funding-block
	justify-content: center 
	padding: 80px 50px 58px
	margin-top: 0
	&__image 
		margin-bottom: 60px 

	&__btn
		margin-top: auto
		cursor: pointer 
		&:hover 
			background-color: $orange
		
		+bp(1300px)
			font-size: 3.4vw
			height: auto 
			min-height: 50px

	+bp($bp-small)
		padding: 30px 20px 20px 

		&__image
			height: 130px
			margin-bottom: 32px
			img 
				max-height: 100%

.consalting-part
	display: grid
	grid-gap: 36px
	grid-template-columns: repeat(2, 1fr)

	div	
		&:nth-child(2)
			grid-column: 2/3
			grid-row: 1/3

	+bp(1200px, 'min')
		.data-block 
			.type-name
				font-size: rem(35px)
		.services__price
			margin: 45px 0

		.services 
			.data-list 
				li 
					margin-bottom: 35px

	+bp($bp-medium)
		display: block

		.data-block, 
		.services
			margin-bottom: 40px 
			&:last-child
				margin-bottom: 0

	+bp($bp-small)
		display: block

		.data-block, 
		.services
			margin-bottom: 30px 

.investors-title
	max-width: 1027px

.services
	&__sublist 
		margin-bottom: 80px
		padding-top: 15px
		+bp($bp-small)
			padding-top: 0 
			margin-bottom: 30px
	&__sublist-item
		position: relative
		padding-left: 40px
		font-size: rem(24px)
		margin-bottom: 15px
		img 
			+center('v')
			left: 0
			width: 25px
		+bp($bp-small)
			font-size: rem(14px)

.help--in 
	margin: -30px 0 30px

.help--in-out 
	padding-bottom: 60px