.desc 
	padding: 140px 0 

	&__content
		&--text--large
			p 
				font-size: rem(30px)
				margin: 35px 0
				font-family: $secondaryFont

	+bp($bp-large)
		padding: 55px 0 
		&__content
			&--text--large
				p 
					font-size: rem(20px)
					margin: 25px 0
	+bp($bp-medium)
		padding: 75px 0
		&__content
			&--text--large
				p 
					font-size: rem(17px)
					margin: 25px 0

	+bp($bp-small)
		padding: 50px 0 30px
		&__content
			&--text--large
				p 
					font-size: rem(18px)
					margin: 20px 0
		&__content
			&--text--large
				p 
					font-size: rem(16px)
					margin: 15px 0
		&--about 
			padding: 0px 0 50px

.about 
	background-color: $gray1
	padding: 150px 0 50px

	&__philosophy 
		background-color: $gray5
		border-radius: 34px 
		padding: 110px 50px
		display: flex 
		justify-content: space-around
		margin-bottom: 180px
		box-shadow: $shadowBlock2

	&__part 
		display: flex 
		flex-wrap: wrap 

	&-unit
		text-align: center
		width: 50%
		padding: 0 20px 
		margin-bottom: 100px

		.btn 
			min-width: 180px
			height: 120px
		
		&__name 
			font-family: $secondaryFont
			font-size: rem(18px)
			margin-top: 26px


	+bp($bp-large)
		padding-top: 100px
		padding-bottom: 50px

		&__philosophy 
			padding: 60px 30px

		&__philosophy
			margin-bottom: 150px

	+bp($bp-large2)
		&-unit
			margin-bottom: 55px
			.btn 
				min-width: 120px
				height: 70px


	+bp($bp-medium)
		padding-top: 80px
		padding-bottom: 40px
		&__philosophy 
			display: block
			margin-bottom: 120px
			padding: 45px 25px

		&-unit
			margin-bottom: 30px

			&__name
				margin-top: 25px

	+bp($bp-small)
		padding: 50px 0 30px
		&__philosophy 
			margin-bottom: 70px
		&-unit
			margin-bottom: 20px

			&__name 
				font-size: rem(16px)
				margin-top: 20px

.team
	background-color: $gray1
	padding: 130px 0 90px

	&--bottom 
		padding-top: 0px
		margin-top: -35px

	&__list 
		display: flex 
		flex-wrap: wrap
		justify-content: space-around
		padding-top: 20px
		max-width: 1250px
		margin: 0 auto

		&--top 
			margin-top: 20px

	&__item 
		width: 280px
		text-align: center
		margin-bottom: 70px

	&__desc
		margin-top: 80px


	.about 
		padding: 0 
		background-color: transparent

	&__part 
		margin-top: 70px

	&-unit
		width: 33.33%
		margin-bottom: 40px

	+bp($bp-large)
		&__list 
			margin-left: 0
		&__desc-text 
			max-width: 1142px

		&__item 
			margin-bottom: 30px

	+bp($bp-medium)
		&__list 
			justify-content: space-around

	+bp($bp-tablet)
		padding: 50px 0
		&--bottom 
			padding-top: 15px
		&__desc 
			margin-top: 35px
		&__list 
			padding-top: 0


	+bp($bp-small)

		&__list 
			display: block 
		&__item 
			width: 100% 
			padding: 0 
			margin-bottom: 45px
			&:last-child
				margin-bottom: 0
		&__part 
			margin: 30px 0 0
		&-unit
			width: 100%
			margin-bottom: 15px
			&:last-child 
				margin-bottom: 0
		&__title
			text-align: center

.mission 
	display: flex 
	&__icon 
		flex-shrink: 0
		width: 300px
		text-align: center
		margin-right: 20px
		display: flex 
		align-items: center
		justify-content: center
		transform: translateY(-50px)
	&__text 
		h2 
			font-weight: 400
			font-family: $secondaryFont
			margin: 0

	+bp($bp-medium)
		display: block 
		&__icon 
			width: 160px
			margin: 0 0 30px
			transform: none

	+bp($bp-small)
		&__text 
			h2 
				font-size: rem(28px)
		&__icon 
			margin: 0 auto 25px 


.works 
	padding: 120px 0 

	+bp($bp-large)
		padding: 90px 0

	+bp($bp-tablet)
		padding: 70px 0

	+bp($bp-small)
		padding: 50px 0




