.team-block 
	max-width: 542px
	margin: 0 auto

	&__image
		max-width: 230px 
		margin: 0 auto 20px

	&__text 
		font-family: $secondaryFont
		max-width: 483px
		margin: 0 auto

	&__status 
		margin: 25px 0 0

	+bp($bp-large)
		&__text 
			max-width: 423px


	+bp($bp-small)
		&__image
			height: auto
			width: 60%
			margin-left: auto 
			margin-right: auto
			margin-bottom: 25px
