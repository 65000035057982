.types-block 
	background-color: $white
	border-radius: $radBlockSmall
	box-shadow: $shadowBlock2
	text-align: center 
	height: 100%
	padding: 50px 30px 40px

	&__image
		+flex_center_b
		height: 160px
		margin-bottom: 35px
		img 
			max-height: 100%

	&__text
		font-size: rem(35px)
		margin-top: 30px

	&--gray 
		background-color: $gray1

		.types-block__image
			+bp($bp-large2, 'min')
				height: 124px

	+bp($bp-large)
		&__text
			font-size: rem(25px)

	+bp($bp-small)
		padding: 25px 15px 30px
		border-radius: 20px
		&__text
			font-size: rem(18px)
		&__image
			height: 110px
			margin-bottom: 20px
