.practice-unit
	background-color: $gray9 
	border-radius: 30px 
	padding: 15px 28px
	width: 100% 
	color: $baseTxt
	text-align: left
	margin-bottom: 20px
	min-height: 180px
	display: flex 
	flex-direction: column 
	justify-content: center

	&__name, 
	&__icon 
		display: inline
		vertical-align: middle

	&__name
		margin: 0 
		font-weight: 700 

	&__icon 
		padding-left: 8px

	&__line
		margin-bottom: 22px
		padding-right: 20px

	+bp($bp-medium)
		min-height: 0 
		padding: 30px 25px

	+bp($bp-small)
		padding: 20px
		border-radius: 20px
		margin-bottom: 10px
		&__icon 
			max-width: 35px
			display: inline-block
		&__line
			margin-bottom: 10px
