.form
	&-block
		h2 
			margin-top: 0
	&-row
		width: 100% 
		display: flex 
		flex-wrap: wrap 
		justify-content: space-between
	&-col 
		width: 32%

		+bp(+600px)
			width: 100%

	&-line
		width: 100%

	&-bottom 
		display: flex 
		align-items: center 
		justify-content: space-between

		+bp(600px)
			flex-direction: column

			.btn 
				width: 100% 

			.form-checkbox
				margin-bottom: 20px 
	
	&-wrapper
		width: 100% 
		display: flex 
		align-items: center 
		justify-content: center 
		overflow-y: auto 
		min-height: 100vh
		background-color: $gray1
	
	&__block 
		max-width: 454px 
		margin: 0 auto
		background-color: $white 
		padding: 40px 32px
		border-radius: 50px
		border: 1px solid $gray6

		+bp($bp-small)
			padding: 30px 12px
	
	&__line 
		margin-bottom: 20px
	
	&__btn 
		border-radius: 45px 
		color: $white
		margin-top: 8px
		height: 52px
		font-size: rem(20px)
		text-transform: uppercase 
		font-weight: 500
	
	&__title 
		text-align: center
		margin-bottom: 27px
		margin-top: 0
		font-size: rem(27px)

	&__flex 
		text-align: center
		margin-top: 18px 

		a 
			font-size: inherit
	
	&__logo 
		text-align: center
		display: block
		font-size: 2.4rem
		margin-bottom: 22px
		&:hover 
			animation: none !important
	
	&__help 
		font-size: rem(13px)
		font-family: $secondaryFont 
		margin-top: 20px 
		margin-bottom: 25px
		color: $gray3
		text-align: center
	
	&__subtitle
		font-size: rem(18px)
		font-family: $secondaryFont 
		text-align: center 
		margin-top: -9px
		margin-bottom: 22px

	&__help-text
		font-size: rem(13px)
		color: red
		transition: color 0.3s
		margin-top: -10px
		margin-bottom: 15px
		display: none
		li 
			color: inherit 
			font-size: inherit
			font-family: $secondaryFont
			background: none 
			padding: 0
			border-radius: 0
			box-shadow: none
			margin: 2px 0
			&.valid 
				color: green

.succes-block
	padding: 50px 
	text-align: center 
	font-family: $baseFont
	margin: 0 
	padding: 0
	display: flex 
	flex-direction: column 
	justify-content: center 
	align-items: center
	font-size: rem(18px)

	&__title 
		font-size: rem(30px)
	
	&__icon 
		max-width: 90px