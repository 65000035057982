.step 
	padding-top: 274px
	padding-bottom: 120px
	&__line
		display: flex 
		flex-wrap: wrap 
		align-items: center 
		margin-bottom: 42px
	&__name
		margin: 12px 27px 12px 0
		text-transform: uppercase
	&__tag 
		max-width: 587px
		width: 100%
		margin: 0 auto
	&__video 
		video 
			width: 100%
	&__block 
		margin-bottom: 120px 
		&:last-child
			margin-bottom: 0

	+bp($bp-large)
		padding-top: 190px

	+bp($bp-tablet)
		padding-top: 120px
		padding-bottom: 60px
		&__line
			margin-bottom: 25px
		&__block 
			margin-bottom: 60px 

	+bp($bp-small)
		&__block 
			margin-bottom: 40px
		.desc-dark__btn
			margin-top: 15px

.video-js
	padding-top: 56.25%
	width: 100%
	height: auto

.vjs-fullscreen 
	padding-top: 0px

.vjs-progress-control
	display: none !important

.video-js .vjs-picture-in-picture-control
	margin-left: auto


