.blog 
	padding: 129px 0 
	&__part 
		display: flex 
		justify-content: space-between
	&__list 
		max-width: 1173px 
		margin-right: 50px 
		width: 100% 
	&__filter 
		flex-shrink: 0
	&__item 
		margin-bottom: 29px
		width: 461px

	&__btn 
		padding-top: 10px
		padding-bottom: 10px
		min-height: 120px
		height: auto !important
		&.is-active 
			background-color: $orange
		

	+bp($bp-large)
		padding: 85px 0
		&__item 
			width: 285px
			margin-bottom: 18px
		&__btn 
			font-size: rem(35px)
			border-radius: 49px
			min-height: 70px
		&__list 
			max-width: 60vw

	+bp($bp-large2)
		&__part 
			flex-direction: column-reverse 
		&__list 
			max-width: 100%
		&__filter 
			display: flex 
			margin-bottom: 0px
			overflow-x: auto
			width: calc(100% + 20px)
			padding-right: 80px

		&__item 
			margin-right: 15px
			margin-bottom: 15px
			width: auto

			.btn 
				font-size: rem(20px)
				min-height: 40px
				padding: 5px 25px
				display: flex
				flex-wrap: nowrap
				box-shadow: none

				span 
					white-space: nowrap

		&__btn 
			&:hover 
				color: $white
				background-color: $purple
			&.is-active 
				background-color: $orange !important


	+bp($bp-medium)
		padding: 90px 0


	+bp($bp-small)
		padding: 50px 0 70px

		&__btn 
			font-size: rem(16px)
			height: 45px 
			border-radius: 30px

		&__item
			margin-right: 8px
			margin-bottom: 8px
			.btn 
				font-size: rem(14px)
				height: 33px
				padding: 0 15px

		&__filter 
			margin-bottom: 20px

.is-hide 
	opacity: 0 
	height: 0px 
	max-height: 0px
	overflow: hidden
	padding: 0 
	margin: 0
