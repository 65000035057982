.top-unit
	display: block 
	text-align: center 
	color: $white
	box-shadow: $shadowBlock
	border-radius: $radBlock 
	background-color: $orange
	padding: 14% 20px 14%
	perspective: 1000px
	transition: 0.3s

	&:hover 
		box-shadow: $shadowHov
		.top-unit__image
			transform: translateY(-16px)

	&--purple
		background-color: $purple
		&:hover
			box-shadow: $shadowHovPurple

	&__image
		height: 162px
		+flex_center_b
		margin-bottom: 12%
		transition: transform 0.35s ease-in-out
		img 
			max-height: 100%

	&__name
		margin: 0

	+bp($bp-large)
		padding-top: 17%
		&__image 
			height: 110px
			margin-bottom: 10%
		


	+bp($bp-tablet)
		padding: 60px 25px 
		border-radius: $radBlockTab

		&__image
			margin-bottom: 50px


	+bp($bp-small2)
		margin-bottom: 30px
		&:last-child
			margin-bottom: 0

	+bp($bp-small)
		border-radius: $radBlockMob 
		padding: 40px 20px

		&__image
			height: 85px
			margin-bottom: 25px
			img 
				max-height: 100px