.social-links
	display: flex
	align-items: center
	&__link 
		display: block
		margin: 0 5px
		width: 23px
		height: 22px
		transition: $transition
		svg 
			width: 100%
			fill: $white
			transition: $transition
		&:hover
			transform: scale(1.2)


		&--youtube
			width: 27px
			&:hover
				svg
					fill: #FF0000

		&--inst
			&:hover 
				svg 
					fill: #F402D0

		&--linkedin
			&:hover 
				svg 
					fill: #0366BB
		&--fb
			width: 19px
			height: 18px
			margin-top: -1px
			&:hover
				svg 
					fill: #2BA2F4
		// &--twitter
		// 	&:hover
		// 		filter: invert(100%);