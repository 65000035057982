.news 
	display: flex 
	color: $baseTxt 
	border-radius: $radBlock
	box-shadow: $shadowBlock
	background-color: $gray1
	padding: 66px 66px 86px 66px
	margin-bottom: 80px
	transition: 1s opacity, 0.25s transform

	&:hover 
		transform: translateY(-2px)

	&__image
		width: 50% 
		img 
			width: 100% 

	&__desc 
		width: 50% 
		padding-left: 29px
		display: flex 
		flex-direction: column 

	&__wrap-button 
		margin-top: auto


	&__name
		margin-bottom: 15px
		&:hover 
			text-decoration: underline

	+bp($bp-large)
		padding: 45px 30px 45px 45px
		&__image 
			width: 363px 
			height: 363px
			flex-shrink: 0
		&__desc 
			width: auto

			p 
				font-size: rem(18px)
				margin-top: 0

		&__name 
			font-size: rem(45px)
			margin-bottom: 12px

	+bp($bp-large2)
		&__image 
			width: 270px 
			height: 270px


	+bp($bp-medium)
		margin-bottom: 40px 
		padding: 40px 30px 
		border-radius: 20px
		&:last-child
			margin-bottom: 0

		&__name 
			font-size: rem(24px)

		&__desc 
			p 
				font-size: rem(15px)

	+bp($bp-tablet)
		margin-bottom: 30px 
		padding: 30px 20px 
		border-radius: 20px
		display: block 

		&__image
			width: 100% 
			margin-bottom: 25px 
			height: auto
		&__desc 
			width: 100%
			padding-left: 0





