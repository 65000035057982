.desc 
	&-box 
		background-color: $white 
		border-radius: 35px 
		padding: 38px 45px 50px
		margin-bottom: 70px 
		box-shadow: 0px 10px 7.5px 0px rgba(0, 0, 0, 0.16)

		&--top 
			padding-top: 75px

		&--top-m 
			margin-top: 40px

		&--dark 
			background-color: $baseTxt 
			color: $white

		&--flat 
			margin-bottom: 25px
			border-radius: 40px
			padding-bottom: 38px

		&--cap 
			padding-bottom: 1px

		&:last-child 
			margin-bottom: 0 
	&-text 
		font-size: rem(22px)
		line-height: 126%
		font-family: $secondaryFont
		margin-top: 27px
		p 
			font-size: inherit
			line-height: inherit
			margin-top: 0 
			&:last-child 
				margin-bottom: 0
		
		&--icons 
			p 
				position: relative
				padding-left: 40px
			img 
				width: 23px
				position: absolute 
				left: 0 
				top: -2px

	&-dark 
		font-size: rem(25px)
		line-height: 1.5
		font-family: $secondaryFont
		padding: 20px 23px
		background-color: $baseTxt 
		box-shadow: 0px 10px 7.5px rgba(0, 0, 0, 0.160784)
		border-radius: $radBlockSmall
		color: $white
		margin: 30px 0
		text-align: center
		min-height: 131px
		display: flex 
		flex-wrap: wrap 
		align-items: center
		p 
			font-size: inherit
			line-height: inherit
			margin-top: 0 
			&:last-child 
				margin-bottom: 0
		a:not(.btn) 
			border-bottom: 1px solid $white 
			transition: border 0.3s ease, color 0.3s ease
			&:hover 
				border-color: transparent
				color: $purple
		&--light 
			background-color: $gray1 
			color: $baseTxt
			padding: 35px 21px
			a:not(.btn)
				border-color: $baseTxt
		&--light-2
			background-color: $gray9 
		&--large 
			font-size: rem(40px)
			padding: 37px

		&--left 
			text-align: left
			padding: 50px 53px
			margin-bottom: 50px 
			&:last-child 
				margin-bottom: 50px
		&--block 
			display: block
			text-align: left
		&--step 
			padding-left: 52px
			padding-right: 52px
			.desc-text 
				max-width: 958px
				margin-left: auto 
				margin-right: auto
		&--purple 
			background-color: $purple
			padding: 22px 54px 
			h4 
				margin: 0

		&__btn
			margin-top: 30px

	&__flex 
		display: flex

		&--reverse 
			flex-direction: row-reverse
			.desc__column--left 
				margin-right: 0
				margin-left: 65px
				max-width: 440px
	&__top 
		margin-top: 70px 

	&__column
		.desc-text 
			margin-top: 14px
		.title-decor 
			margin-bottom: 0
		&--left 
			max-width: 463px
			width: 100%
			margin-right: 20px
			flex-shrink: 0
		&--left-large
			max-width: 483px
			margin-right: 35px
		&--left-small 
			max-width: 336px

	&__where 
		max-width: 772px
		&--mid 
			max-width: 661px

	&-grid 
		display: grid
		grid-template-columns: repeat(3, 1fr)
		grid-gap: 35px
		margin-top: 50px

		&--two 
			grid-template-columns: repeat(2, 1fr)
			grid-gap: 20px
			.desc-box 
				margin-bottom: 0

		&__item 
			text-align: left

		&__name
			padding: 15px 0 25px

	&__why-part
		padding: 0 
		box-shadow: none

	&__bowl 
		max-width: 850px
		width: 100% 
		margin: 0 auto
	&--list 
		padding: 10px 0

	&__block-text 
		margin-bottom: 60px
		

	+bp($bp-large)
		&-text 
			font-size: rem(20px)
		&-dark 
			font-size: rem(20px)
			&--large 
				font-size: rem(30px)
		&__bowl 
			max-width: 650px

	+bp($bp-large2)
		&__column 
			&--left 
				max-width: 380px
			&--left-small 
				max-width: 280px
		&__where 
			max-width: 600px

	+bp($bp-medium)
		&__flex 
			display: block
			&--reverse 
				.desc__column--left 
					margin-left: 0px
					max-width: 100% 
		&__column 
			&--left
				max-width: 100% 
				margin-right: 0
		&-grid 
			display: block
		&__why-part 
			margin-top: 40px

	+bp($bp-tablet)
		&-dark
			&--large 
				font-size: rem(24px)
		.grid 
			display: block

	+bp($bp-small)
		&-box 
			padding: 20px 20px 30px 
			border-radius: 20px
			margin-bottom: 50px
			&--flat 
				margin-bottom: 20px
		&-text 
			font-size: rem(15px)
		&-dark 
			font-size: rem(15px)
			padding: 20px 15px
			border-radius: 20px
			margin: 20px 0
			min-height: 0
		&__top 
			margin-top: 32px
		&-grid 
			margin-top: 20px
		&__block-text
			margin-bottom: 30px

		&__column 
			.title-decor 
				font-size: rem(22px)


.title-decor 
	margin: 0 0 10px 
	font-size: rem(50px)
	font-weight: 500
	line-height: 126%
	span 
		border-bottom: 5px solid $orange

	+bp($bp-large2)
		font-size: rem(40px)

	+bp($bp-small)
		font-size: rem(28px)

		span 
			border-width: 2px


.programme 
	padding: 120px 0
	&--bot 
		padding-top: 0
	&-title 
		+bp($bp-large2, 'min')
			font-size: rem(60px)
		&--bot 
			margin-bottom: 30px
			max-width: 860px
		+bp($bp-small2)
			font-size: rem(28px)
	&-btns 
		margin: 60px 0 
	&-table 
		max-width: 1040px 
		margin: 0 auto

		table 
			width: 100%
			tr 
				&:first-child 
					td 
						vertical-align: top
				td 
					padding: 15px 7px
					text-align: center
					button 
						width: 196px
						min-height: 74px 
						display: flex 
						align-items: center 
						justify-content: center
						color: $white
						padding: 7px 14px 
						font-size: rem(24px)
						text-align: center
						border-radius: 15px 
						background-color: $baseTxt 
						cursor: default
						margin: 0 auto
						box-shadow: 0px 10px 7.5px rgba(0, 0, 0, 0.160784)
		
		&--full 
			max-width: 100%
			margin-bottom: 45px
			table
				tr 
					&:first-child 
						td 
							padding-top: 0
					&:last-child 
						td 
							border-bottom: none 
				tr 
					td 
						font-size: rem(18px)
						line-height: rem(24px)
						padding: 20px 15px
						border-bottom: 1px solid $gray9
						border-right: 1px solid $gray9
						font-family: $secondaryFont
						vertical-align: middle

						&:last-child 
							border-right: none

						button 
							width: 100%
							padding-left: 33px 
							padding-right: 33px
							white-space: nowrap
							box-shadow: none

		&__list 
			margin-top: 60px 
		&__item 
			display: flex 
			align-items: center
			font-size: rem(16px) 
			font-family: $secondaryFont
			margin-bottom: 12px
		&__icon 
			width: 43px
			height: 34px
			+flex_center_b 
			flex-shrink: 0 
			margin-right: 22px
			box-shadow: 0px 10px 7.5px rgba(0, 0, 0, 0.160784)
			border-radius: 10px 
			background-color: $white
			font-size: rem(30px)
			line-height: 1
			svg 
				width: 12px 
				height: 12px
	&__text-large
		margin-top: 50px 
		margin-bottom: 40px
		+bp($bp-large2, 'min')
			font-size: rem(20px)

	&__btn 
		margin-top: -20px
	&__text 
		p 
			margin: 40px 0

	+bp($bp-large2)
		&-table 
			table 
				tr 
					td 
						button 
							font-size: rem(16px)
							width: 130px
			&--full 
				table 
					tr 
						td 
							button 
								font-size: rem(18px)
								width: 100%
			&__list 
				margin-top: 40px

	+bp($bp-medium)
		padding: 90px 0
		&--bot 
			padding-top: 0
		&-table 
			table 
				tr 
					td 
						padding: 10px 5px
						button 
							font-size: rem(14px)
							width: 100px
							padding-left: 4px
							padding-right: 4px
							min-height: 50px
						img 
							max-width: 30px
			
			&--full 
				font-size: rem(16px)



						
			&__list 
				margin-top: 40px

	+bp($bp-tablet)
		.grid 
			display: block
		&-btns 
			display: block
			.btn 
				margin-bottom: 25px
				&:last-child 
					margin-bottom: 0

	+bp($bp-small)
		padding: 50px 0

		&__wrapper 
			padding-right: 0

		&--bot 
			padding-top: 0

		&-table 
			overflow-x: auto
			margin-left: -10px
			table 
				min-width: 345px
				tr 
					td 
						padding: 4px 2px
						button 
							font-size: rem(9px)
							width: 60px
							padding-left: 3px
							padding-right: 3px
							min-height: 30px
							border-radius: 5px
						img 
							max-width: 20px
			&--full 
				margin: 0 0 30px
				overflow-x: auto
				table
					min-width: 600px
					tr 
						td
							font-size: rem(13px) 
							line-height: 1.25
							padding: 7px 2px
							button 
								width: auto
								font-size: rem(13px)
								padding: 0 10px
								border-radius: 10px

			&__list 
				margin-top: 40px
			&__icon 
				width: 32px
				height: 23px
				margin-right: 10px
				border-radius: 5px

.programme__text 
	font-family: $secondaryFont