*
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
	-moz-tap-highlight-color: rgba(0, 0, 0, 0)
	box-sizing: border-box

*::before,
*::after
	box-sizing: inherit

html, :root
	font-size: $baseRem
	overflow-x: hidden

	+bp(1024px)
		-webkit-text-size-adjust: 100%

body
	font-family: $baseFont
	font-size: $baseRem
	color: $baseTxt
	min-width: 320px
	padding: 0
	margin: 0
	overflow-x: hidden
		
	::-moz-selection
		background: $gray3
	::selection
		background: $gray3

.page
	display: flex
	flex-direction: column
	min-height: 100vh
	width: 100%
	background-color: $white 
	position: relative 
	z-index: 2

.main
	flex-grow: 1
	

img
	max-width: 100%

.hidden
	visibility: hidden 
	position: absolute
	top: 0 
	left: 0

.wrapper
	width: 100%
	max-width: $content-wrapper-width + ($content-wrapper-gap * 2)
	padding: 0 $content-wrapper-gap
	margin: 0 auto

	&--large 
		max-width: 100%

	&--small
		max-width: $content-wrapper-width-small + ($content-wrapper-gap * 2)

	&--min 
		max-width: 1250px
		
	+bp($bp-large)
		padding: 0 50px

	+bp($bp-large2)
		padding: 0 40px

	+bp($bp-tablet)
		padding: 0 20px

.grid
	display: grid
	grid-template-columns: repeat(2, 1fr)
	grid-gap: 55px
	&--large2
		grid-gap: 70px
	
	&--center
		display: flex 
		flex-wrap: wrap
		justify-content: center
		grid-gap: 0 !important
		margin: 0 -26px
		.services 
			width: calc(50% - 52px)
			margin: 0 26px 65px
		
		+bp($bp-tablet)
			width: 100% 
			display: block
			margin: 0 
			.services
				width: 100%
				margin: 0 0 40px

	+bp($bp-large)
		grid-gap: 30px

		&--large2
			grid-gap: 70px

	+bp($bp-large2)
		&--large2
			grid-template-columns: repeat(1, 1fr)

	+bp($bp-tablet)
		&--mobile
			display: block

	+bp($bp-small2)
		display: block

.bg-gray
	background-color: $gray1


.js-scroll-section
	scroll-margin-top: 30px

	+bp($bp-medium)
		scroll-margin-top: 50px

.bg-purple
	background-color: $purple

.message 
	@extend .bg-purple 
	color: $white 
	padding: 15px 
	text-align: center 
	font-size: rem(18px)
	position: relative 
	z-index: 6

	& ~ .header 
		top: 30px
	
		+bp(1100px)
			top: 70px 

	+bp($bp-small)
		font-size: rem(15px)
		padding: 10px 
	
	+bp(340px)
		font-size: rem(13px)
		padding: 10px 

.list-default
	margin-top: 30px 

	li 
		position: relative 
		text-align: left 
		padding-left: 24px 
		&:before 
			content: ''
			width: 7px
			height: 7px 
			border-radius: 50% 
			background-color: $baseTxt
			position: absolute 
			left: 5px 
			top: 7px

.icon-chain
	width: 18px
	height: 14px 
	display: inline-block
	cursor: pointer
	img 
		&:nth-child(1)
			display: block
		&:nth-child(2)
			display: none
	&:hover
		img
			&:nth-child(1)
				display: none
			&:nth-child(2)
				display: block

.hero__tx.text-large 
	.btn 
		margin: 30px 0

	.company__wrap
		margin-top: 40px

		+bp($bp-small)
			margin-top: 20px
	p 
		margin-top: 20px

#uuid
	&:hover 
		color: $purple
		.icon-chain 
			img
				&:nth-child(1)
					display: none
				&:nth-child(2)
					display: block

