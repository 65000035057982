.letter
	height: 100vh 
	background-color: $gray1
	display: flex 
	justify-content: center 
	align-items: center
	text-align: center
	min-height: 868px

	&--h 
		height: auto

	&-block
		max-width: 885px 
		width: 100% 
		margin: 50px auto 0

		&__title 
			font-size: rem(70px)
			margin: 0 0 45px

		&__form 
			background-color: $baseTxt 
			padding: 45px 36px 56px 
			border-radius: 50px 
			color: $white 
		&__subtitle 
			font-size: rem(35px)
			margin: 0 0 60px
			text-align: center
			font-family: $secondaryFont
		&__line 
			margin-bottom: 30px 

			&:last-child 
				margin-bottom: 0

			&--btn 
				margin-top: 40px
		&__btn 
			margin-top: 50px

	+bp($bp-large)
		min-height: 0
		padding-top: 240px
		padding-bottom: 100px
		height: auto
		&--h 
			padding-bottom: 150px
		&-block
			max-width: 650px
			margin-top: 0
			&__title 
				margin-bottom: 30px
				font-size: rem(50px)
			&__subtitle 
				font-size: rem(25px)
				margin: 0 0 30px
			&__form 
				max-width: 627px
				padding: 28px 30px 45px
				
	+bp($bp-large2)
		padding-top: 180px

	+bp($bp-tablet)
		height: auto 
		min-height: 0
		display: block
		padding-top: 130px
		padding-bottom: 90px


	+bp($bp-small)
		padding: 120px 0 50px
		&--h 
			padding-bottom: 75px
		&-block 
			margin: 0 
			&__form
				border-radius: 20px 
				padding: 30px 20px

			&__subtitle 
				font-size: rem(18px)
				margin-bottom: 25px

			&__line 
				margin-bottom: 15px
				&--btn 
					margin-top: 15px

			&__title 
				font-size: rem(34px)
				margin-bottom: 30px

.lapse 
	text-align: left 
	max-width: 887px
	width: 100%
	margin: 0 auto
	&__icon 
		text-align: center 
		margin: 72px 0 85px

		+bp($bp-small)
			margin: 30px 0 40px
			height: 130px
			img 
				max-height: 100%
