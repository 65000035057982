.depiction
  &.bg-gray
    background-color: $gray1
  &--rep
    padding: 20px 0 60px
    .desc-dark 
      margin-top: 0
    
    +bp($bp-tablet)
      padding-top: 0
      padding-bottom: 50px
    
    +bp($bp-small2)
      padding-bottom: 20px

.rep 
  &__grid 
    display: flex
    margin: 0 -17px

    +bp(1250px)
      display: block
      margin: 0
  &__column 
    width: 50% 
    padding: 0 17px

    +bp(1250px)
      width: 100% 
      padding: 0
      margin-bottom: 55px

      &:last-child 
        margin-bottom: 0
    
    +bp($bp-small2)
      margin-bottom: 25px

  &__block 
    color: $white 
    background-color: $baseTxt 
    border-radius: 40px 
    padding: 50px 50px 55px
    margin-bottom: 55px 
    &:last-child 
      margin-bottom: 0
  &__title 
    margin-bottom: 35px
  &__text 
    padding: 60px 0 80px
    font-size: rem(18px)
  &-block
    padding: 25px 30px
    border-radius: 30px 
    background-color: $gray9
    color: $baseTxt
    margin-bottom: 24px 

    &:last-child 
      margin-bottom: 0

    &__top 
      display: flex 
      justify-content: space-between 
      align-items: center
    
    &__name 
      display: flex 
      align-items: center 
      margin-right: 30px 
      font-size: rem(35px)
      font-weight: 500
    
    &__icon 
      width: 40px 
      flex-shrink: 0
      margin-right: 12px
      transform: translateY(-3px) 

      img 
        width: 100%
    
    &__price
      white-space: nowrap
      font-size: rem(33px)
      font-family: $secondaryFont
      font-weight: 500
    
    &__desc 
      font-size: rem(18px)
      margin-top: 8px
      font-family: $secondaryFont

      &--large 
        margin-top: 0 
        font-size: rem(25px)
        line-height: 1.2
        font-family: $baseFont
      
      &--middle 
        font-size: rem(24px)
  
  +bp($bp-small2)
    &__block 
      padding: 25px 20px
      border-radius: 20px 
      margin-bottom: 25px
    &__title 
      margin-bottom: 20px
    
    &__text 
      padding: 30px 0 45px
    
    &-block 
      border-radius: 20px 
      padding: 15px
      margin-bottom: 12px

      &__name 
        font-size: rem(22px)
        margin-right: 10px
      
      &__icon 
        width: 25px 
        margin-right: 6px
      
      &__price 
        font-size: rem(21px)
      
      &__desc 
        &--large 
          font-size: rem(21px)
        &--middle 
          font-size: rem(19px)
  
  +bp(360px)
    &-block 
      &__name 
        font-size: rem(19px)
      &__price 
        font-size: rem(16px)
      &__icon 
        width: 19px

