.how-block 
	display: flex
	margin-bottom: 60px 
	box-shadow: 0px 10px 7.5px rgba(0, 0, 0, 0.160784)
	border-radius: $radBlockSmall 
	background-color: $gray1
	padding: 35px 25px

	&__image
		width: 258px
		margin-right: 40px
		flex-shrink: 0
	&__desc
		padding-top: 20px
		.desc-text
			margin-top: 35px

	+bp($bp-medium)
		margin-bottom: 30px
		&__image
			width: 180px
			margin-right: 15px

	+bp($bp-small)
		display: block 
		padding: 20px
		border-radius: 20px
		text-align: center
		&__image
			width: auto 
			height: 110px
			text-align: center
			margin-right: 0 
			margin-bottom: 15px
			img 
				max-height: 100%
		&__desc	
			padding-top: 0

			.desc-text
				margin-top: 15px
