.pitch-block 
	padding: 34px 47px 47px
	background-color: $baseTxt 
	color: $white
	box-shadow: 0px 10px 7.5px rgba(0, 0, 0, 0.160784)
	border-radius: $radBlockSmall
	margin-bottom: 23px

	&__line
		display: flex 
		align-items: center 
		justify-content: space-between 
		margin-bottom: 40px

	&__title
		margin: 0
		font-size: rem(35px)

	&__label 
		margin-right: 10px
		margin-left: auto 
		color: $white 
		background-color: $orange 
		font-size: rem(24px)
		font-weight: 500
		padding: 1px 9px
		border-radius: 30px
		box-shadow: 0px 10px 7.5px rgba(0, 0, 0, 0.160784)
	&__price
		font-size: rem(20px)
		min-width: 85px 
		text-align: right
	
	&__title 
		border-radius: 50px 
		background-color: $orange 
		font-size: rem(30px)
		padding: 5px 20px

	+bp($bp-small)
		padding: 25px 20px
		border-radius: 20px 

		&__label 
			font-size: rem(16px)
		&__price
			font-size: rem(14px)
			min-width: 50px
		&__title
			font-size: rem(20px)
		&__line
			margin-bottom: 12px

