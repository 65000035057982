.form-field
	position: relative
	display: block
	width: 100%
	
	&[disabled],
	&.disabled
		opacity: .5
		pointer-events: none


	&__input
		font-family: $secondaryFont
		display: block
		width: 100%
		padding: 0px 10px 0px
		height: rem(41px)
		line-height: rem(41px)
		border: 2px solid $white
		background-color: $white
		color: $baseTxt
		font-size: rem(20px)
		transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out, border-color 0.35s ease-in-out
		border-radius: 13px

		&::placeholeder 
			color: $gray2
		
		&:focus
			border-color: $gray2

		&--large 
			height: 65px 
			line-height: 65px 
			padding: 0 25px
			font-size: rem(35px)
			border-radius: 45px

		&--gray 
			border-color: $gray8 
			color: $gray8 
			height: rem(53px)
			line-height: rem(53px)
			&::placeholder
				color: $gray8
		
		&--form 
			border-radius: 45px
			height: 52px 
			line-height: 52px 
			border: 1px solid $gray6
			padding: 0 22px


		+bp($bp-small2)
			font-size: rem(17px)
			&--large 
				height: 50px 
				line-height: 50px 
	
	&__label 
		font-size: rem(17px)
		color: $gray2
		position: absolute
		font-family: $secondaryFont 
		top: 17px 
		left: 18px
		padding: 0 4px 
		z-index: 2
		transform-origin: 0 0
		transition: transform .3s linear
		will-change: transform
		opacity: 0.85
		pointer-events: none
		background-color: $white 
		z-index: 2


	&.has-danger
		.form-field__input 
			border-color: red 

			&::placeholder 
				color: red
	
	&--focused
		.form-field__label
			transform: translate(1px,-24px) scale(0.8)
			transform-origin: 0 0


	+bp($bp-large)
		&__input
			height: rem(45px)
			line-height: rem(45px)
			&--gray 
				height: rem(53px)
				line-height: rem(53px)

			&--form 
				height: 52px 
				line-height: 52px


	
.form 
	&__line
		margin-bottom: 6px	
		&:last-child
			margin-bottom: 0	
			margin-top: 12px
		+bp($bp-large)
			margin-bottom: 8px

.pristine-error.text-help
	display: none
