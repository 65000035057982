.glossary
	position: relative
	padding-top: 240px
	&__top 
		width: 100%
		background-color: $white
		padding: 90px 0 20px
		position: absolute 
		top: 0 
		left: 0
		transition: 0.3s ease
		&.fixed-g
			position: fixed 
			z-index: 9999
			padding: 10px 0
			background-color: $gray1
			.glossary__list
				flex-wrap: nowrap
				li 
					margin-bottom: 0
			
			+bp(1250px)
				.glossary__list
					flex-wrap: wrap
					li 
						margin-bottom: 8px
			+bp(480px)
				padding: 8px 0
				.glossary__list
					li 
						margin-bottom: 3px
						a 
							transform: scale(0.85)

	&__list 
		display: flex 
		flex-wrap: wrap
		max-width: 1200px
		margin: 0 auto

		li 
			width: calc(100% / 11) 
			margin-top: 0 
			margin-bottom: 35px
			cursor: pointer
		
		a 
			+flex_center_b
			background-color: $baseTxt 
			color: $white 
			border-radius: 10px 
			width: 50px 
			height: 48px
			font-size: rem(23px)
			transition: 0.3s ease 
			+bp(1200px, 'min')
				&:hover 
					background-color: $purple
			&.is-active
				background-color: $purple

	&__unit
		max-width: 1200px 
		margin: 0 auto
		padding: 60px 0
		border-bottom: 1px solid $gray1
		border-top: 1px solid $gray1
		&:first-child 
			border-top: none 
		strong 
			color: $purple
			padding-right: 5px
		span 
			font-family: $secondaryFont
		h6 
			font-size: rem(32px)
	
	+bp($bp-large)
		&__list, 
		&__unit 
			max-width: 100%
	
	+bp($bp-medium)
		padding-top: 180px
		&__top 
				padding: 60px 0 40px
		&__list 
				li 
					margin-bottom: 20px
	
	+bp($bp-tablet)
		&__list 
				a 
					width: 40px 
					height: 40px
					font-size: rem(17px)
					border-radius: 8px
	+bp($bp-small)
		padding-top: 110px
		&__top 
				padding: 40px 0 15px
		&__list 
				li
					margin-bottom: 7px
				a 
					width: 23px 
					height: 23px
					font-size: rem(12px)
					border-radius: 4px
		&__unit
			padding: 30px 20px
			h6 
				margin-bottom: 15px 
				font-size: rem(24px)
		&__sections 
			.wrapper
				padding: 0

+bp(1250px)
	.glossary__unit.js-scroll-section
		scroll-margin-top: 112px

+bp($bp-small)
	.glossary__unit.js-scroll-section
		scroll-margin-top: 53px



.dictionary
	margin: 100px 0
	&__unit
		display: block
		background-color: $gray1
		padding: 50px
		transition: 0.3s ease
		border-radius: 20px
		margin-bottom: 40px 
		&:last-child 
			margin-bottom: 0
		+bp($bp-small)
			margin-bottom: 20px
		&:hover 
			color: $purple
		h3 
			margin: 0
	
	+bp($bp-small)
		margin: 50px 0
		border-radius: 15px
		&__unit
			padding: 20px