.grant 
	background-color: $gray1 
	border-radius: 30px 
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
	padding: 36px 22px
	&__name 
		+flex_center_b
		display: inline-flex
		width: 100%
		text-align: center 
		background-color: $purple 
		color: $white
		height: 100%
		font-size: rem(25px)
		border-radius: 30px
		padding: 30px 25px
		font-family: $secondaryFont
		line-height: 1.2
		transition: $transition
		&:hover 
			background-color: $orange
	&__value 
		display: inline
		border-bottom: 1px solid $white

	+bp($bp-tablet)	
		margin-bottom: 20px
		padding: 18px 15px 
		border-radius: 20px 

		&:last-child 
			margin-bottom: 0

		&__name
			padding: 15px 
			border-radius: 15px 
			font-size: rem(18px)
