.popup
	position: fixed
	top: 0
	right: 0
	bottom: 0
	left: 0
	opacity: 0
	visibility: hidden
	max-height: 100vh
	overflow-y: auto
	overflow-x: hidden
	z-index: -1
	transition: opacity 0.3s linear
		
	&__wrapper
		width: 100%
		display: flex 
		align-items: center 
		justify-content: center
		min-height: 100% 
		position: relative
		
		&:after
			content: ''
			display: block
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			background: rgba(33, 36, 39, 0.51)
			z-index: 1
			transition: 0.6s cubic-bezier(.8,.24,.2,.77)



	&__overlay
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		transition: all 0.3s linear
		transition-delay: 0.3s
		z-index: 2
		opacity: 0

	&__title
		font-size: rem(25px)
		font-weight: 500
		margin-bottom: 28px
		padding-right: 25px

	&__inner
		max-width: 800px
		width: 100%
		height: auto
		position: relative
		color: $baseTxt
		z-index: 5
		transition-delay: 0s
		transition: all 0.3s linear
		opacity: 0

	&__close
		cursor: pointer
		position: absolute
		right: -24px
		top: -24px
		width: 48px
		height: 48px
		background: transparent
		z-index: 5
		transition: all 0.3s linear
		display: flex
		align-items: center
		justify-content: center
		border-radius: 50%

		svg
			width: 25px
			height: 25px
			stroke: $white
			stroke-width: 2px
			transition: .25s

		&:hover
			svg
				stroke: $purple

	&__content
		width: 100%
		position: relative
		padding: 46px 70px 
		background-color: $white
		max-width: 600px
		width: 100% 
		margin: 0 auto
		border-radius: 57px
		border: 1px solid $gray7
		transform: scale(0.55)
		transition: 0.6s cubic-bezier(.8,.24,.2,.77)

		.modal-text
			transform: translateX(100%)
			transition: 0.5s
			opacity: 0
			transition-delay: 0.4s

	&__layout
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		transition: all 0.3s linear
		transition-delay: 0.3s
		z-index: 2
		opacity: 0


	// active state
	&.is-show
		opacity: 1
		visibility: visible
		z-index: 100

		.popup__overlay
			opacity: 0.1
			transition-delay: 0s

		.popup__inner
			// transition-delay: 0.3s
			opacity: 1

		.popup__content .modal-text,
		.popup__wrapper:after
			opacity: 1
		.popup__content
			transform: none

	+bp(850px)
		&__wrapper
			padding-left: 20px
			padding-right: 20px
		&__content
			border-radius: 25px
			padding: 30px 20px
		&__close 
			top: -45px

	+bp($bp-small2)
		&__btn 
			font-size: rem(20px)



	

.modal 
	text-align: center
	&__name 
		text-align: center 
		margin: 0 auto 40px
		max-width: 500px

	&__btn 
		font-size: rem(30px)
		width: 100% 
		border-radius: 45px
		height: 77px
		margin-top: 20px

		&--small 
			max-width: 140px 
			margin: 0 auto

	&__text 
		text-align: center

	+bp($bp-small2)
		&__btn 
			font-size: rem(16px)
			height: 53px
		&__name
			margin-bottom: 20px

.video-popup 
	padding: 0

	.popup__wrapper 
		padding: 0

	.popup__inner
		max-width: 100% 
		width: 100% 
		height: 100vh
		background-color: $black
		display: flex 
		align-items: center
	
	.popup__content 
		background-color: transparent 
		padding: 0
		border-radius: 0
		border: none 
		position: relative
		overflow: hidden
		max-width: 100%
		width: 100%
		margin: 0 !important
		padding-top: 56.25%

		iframe 
			position: absolute
			top: 0
			left: 0
			bottom: 0
			right: 0
			width: 100%
			height: 100%
	
	.popup__close 
		position: fixed 
		top: 5px
		right: 5px 
		background-color: $purple
		border-radius: none

		svg 
			width: 16px 
			height: 16px


			




