.link,a
	display: inline
	color: inherit
	font-size: inherit 
	line-height: inherit
	text-decoration: none
	transition: color 0.4s ease, border 0.4s ease
	outline: none

	&--bor
		border-bottom: 1px solid rgba(255, 255, 255, 0.7)
		font-weight: 400
		&:hover 
			border-color: transparent 

	&--bor-dark
		border-color: $baseTxt

	&--default
		&:hover
			color: $purple
	&--purple 
		color: $purple 
		font-size: rem(20px)

		&:hover 
			color: $orange
	
	&--orange 
		color: $orange 
		&:hover 
			color: $purple
	
	&--bor-gray 
		border-color: $gray3



.link-bor 
	border-bottom: 1px solid $baseTxt 
	font-weight: 400
	&:hover 
		border-color: transparent
	