.company 
  padding-bottom: 100px
  min-height: 101vh

  +bp($bp-small)
    padding-bottom: 50px
     
  &__btn 
    cursor: pointer
    &.is-hide 
      display: none
  
  &__title-decor 
    margin: 60px 0 50px 
    display: flex 
    align-items: center 

    img 
      margin-right: 30px
  
    +bp($bp-small)
      align-items: flex-start
      margin: 35px 0 20px
      img 
        margin-right: 10px
  
  &__top 
    display: flex 
    margin-bottom: 50px 
    align-items: flex-end 
    justify-content: space-between

    +bp($bp-medium)
      display: block
    
    +bp($bp-small)
      margin-bottom: 25px
  
  &__title 
    margin-bottom: 0
  
  &__top-block 
    display: none
  
  &__top-links 
    display: flex

    +bp($bp-medium)
      justify-content: flex-start
  
  &__line 
    display: flex 
    padding: 40px 0 35px

    +bp($bp-medium)
      min-width: 980px 

  &__line-wrap
    +bp($bp-medium) 
      width: calc(100% + 40px)
      padding-right: 60px
      overflow-x: auto
  
  &__top-link 
    transform: translateY(-8px)
    margin-left: auto 
    margin-right: 50px 
    &:last-child 
      margin-right: 0
    
    +bp($bp-medium)
      display: inline-block
      margin-right: 35px 
      margin-top: 30px
      margin-left: 0
    
    +bp($bp-small)
      margin-top: 20px
  
  &__block-line 
    height: 36px
    position: relative 
    &:first-child 
      border-radius: 30px 0 0 30px 
    &:last-child 
      border-radius: 0 30px 30px 0
  
  &__block-line-title 
    width: 100%
    position: absolute
    top: -30px
    left: 50% 
    transform: translateX(-50%)
    font-size: rem(12px)
    color: $purple
    font-weight: 500
    text-align: center

    &--line 
      white-space: nowrap
  
  &__block-line-value 
    position: absolute
    top: 50px
    right: 0 
    font-size: rem(12px)

    &--start 
      left: 0 
      right: auto
  
  &__text 
    font-size: rem(18px)
    font-family: $secondaryFont 

    &--top 
      margin-top: 45px

    a 
      color: $purple
      padding-left: 5px 
      &:hover 
        color: $orange
    
    +bp($bp-small2)
      font-size: rem(22px)
      &.desc-text 
        font-size: rem(16px)
  
  &__grid 
    display: grid 
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 35px

    +bp(1100px)
      grid-template-columns: repeat(3, 1fr)
    
    +bp($bp-medium)
      grid-gap: 20px
    
    +bp($bp-small2)
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 15px
  
  &__part 
    display: flex 
    justify-content: space-between
    margin-top: 60px 

    +bp($bp-medium)
      display: block
  
  &__chat 
    width: 100%
    margin: 0 auto

    +bp($bp-medium)
      margin-left: -10px
      width: calc(100% + 20px)

      .company__column-title
        padding-left: 5px
      .company__sub-title
        position: static
  
  &__column 
    width: 49% 
    position: relative

    &--center 
      margin-left: auto 
      margin-right: auto

    &--right 
      width: 471px
      margin-left: 35px
    
    +bp($bp-medium)
      width: 100% 
      margin-left: 0
      margin-bottom: 60px 
      &:last-child 
        margin-bottom: 0

  
  &__column-title 
    margin-bottom: 50px
    position: relative 

    +bp($bp-small)
      margin-bottom: 25px

  &__sub-title 
    font-size: rem(24px)
    position: absolute 
    left: 0 
    top: 95% 
    line-height: 1
  
  &__block-video 
    margin-bottom: 60px
    overflow: hidden 
    cursor: pointer
    position: relative

    img 
      transition: transform 0.7s 
      transform-origin: 50% 100%
    
    &:hover 
      img 
        transform: scale(1.05)
    
    svg 
      width: 80px
      height: 80px
      position: absolute 
      left: 50% 
      top: 42% 
      transform: translateX(-50%) translateY(-50%)
      filter: invert(100%)

      +bp($bp-small)
        width: 50px
        height: 50px

  &__video 
    position: relative
    overflow: hidden
    width: 100%
    padding-top: 56.25%

    iframe 
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0
      width: 100%
      height: 100%
  
  &__chat 
    +bp($bp-medium)
      iframe
        width: 100% !important
  
  &__wrap 
    margin-bottom: 25px 

    &:last-child 
      margin-bottom: 0
    
    .data__title 
      margin-top: 0
    
    &--top 
      padding: 0 
      overflow: hidden
  
  &__about 
    color: #91919F 
    text-align: center 
    font-size: rem(12px)
    margin-top: 25px 
    opacity: 0.8
    font-weight: 300
  
  &__title-flex 
    display: flex
    align-items: center 
    justify-content: space-between 

    .company-data 
      margin-bottom: 0
      transform: translateY(15px)
    
    .company-data__tx
      margin-right: 0 
      margin-left: 80px
    
    +bp(1054px)
      display: block

      .company-data 
        margin: -35px 0 40px
    
    +bp($bp-small)
      .company-data 
        margin-top: -10px 
      .company-data__tx
        margin-left: 0
  
  &__flex
    display: flex
    align-items: center 
    justify-content: space-between
    margin-bottom: 50px 

    +bp($bp-small)
      margin-bottom: 25px
  
  &__mark 
    display: block
    font-size: rem(30px)
    font-weight: 500
    background-color: #7C7C7C
    color: $white 
    padding: 8px 15px 
    border-radius: 30px

    &.is-active 
      background-color: $purple

    &--link
      transition: color 0.3s 
      &:hover 
        background-color: $purple
    
    +bp($bp-small)
      font-size: rem(17px)
    
    +bp(370px)
      font-size: rem(15px)

.company-data
  margin-bottom: 50px 
  &__line 
    display: flex 
    align-items: flex-end 
    color: #636982 
    font-weight: 500 
    font-size: rem(22px)
    transform: translateY(-11px)

    span 
      line-height: 1
  &__line-active 
    color: $purple 
    font-size: rem(42px)
    transform: translateY(3px)
  
  &__tx 
    font-size: rem(25px) 
    margin: 0 100px 0 50px
    color: $purple
    font-family: $secondaryFont
  
  &__chat 
    width: 471px 
    height: 578px
  
  &__flex 
    display: flex
    align-items: center
  
  &__link 
    color: $orange
    cursor: pointer
    font-size: rem(20px)
    font-weight: 500

    &.is-hide 
      display: none 

    &:hover 
      color: $purple 
  
  +bp($bp-small)
    margin-bottom: 25px
    &__flex
      display: block
    
    &__tx 
      margin: 12px 0 15px
  

.company-unit 
  background-color: $baseTxt 
  padding: 40px 15px 25px 40px
  border-radius: 40px 
  color: $white
  min-height: 251px

  &--cl 
    .company-unit__num
      display: none

      &--mark 
        display: block

  &__title 
    margin-top: 0
    margin-bottom: 25px
    font-size: rem(45px)
  &__data 
    font-size: rem(24px)
  
  &__num 
    font-size: rem(46px)
    &--mark 
      color: $orange
  
  +bp($bp-medium)
    min-height: 180px
    &__title 
      font-size: rem(33px)
    &__num 
      font-size: rem(35px)
  
  +bp($bp-small2)
    min-height: 140px
    padding: 25px 12px 25px 25px
    border-radius: 25px
    &__title 
      font-size: rem(24px)
    &__num 
      font-size: rem(27px)
  
  +bp(370px)
    min-height: 110px
    padding: 15px 7px 15px 15px
    border-radius: 20px
    &__title 
      font-size: rem(20px)
    &__num 
      font-size: rem(27px)
    &__num-all 
      font-size: rem(18px)

.top 
  &__links 
    display: flex 
    flex-wrap: wrap
    gap: 20px 
    margin-bottom: 40px

    .top__title 
      cursor: pointer 
      display: flex 
      align-items: center 
      transition: backfround-color 0.4s 
      height: 45px

      +bp($bp-small)
        height: 46px

      &.is-hide 
        display: none

      &.is-active 
        background-color: $orange


    +bp($bp-small)
      gap: 15px
  
    
.margin 
  margin: 30px 0 !important

.how
  padding-bottom: 120px
  &__help 
    font-size: rem(36px)
    font-weight: 900 
    margin: 35px 0 80px
    text-align: center
  
  &__part 
    display: flex 
    justify-content: space-between
    flex-wrap: nowrap
    padding: 0 15px
  
  &-unit 
    max-width: 330px
    display: flex 
    flex-direction: column 
    margin: 0 15px
    &__count
      width: 119px
      height: 111px
      margin: 0 auto 22px 
      border-radius: 27px
      font-size: 5rem
    &__btn 
      margin: 0 auto
  
  &__list 
    padding-top: 70px 

    +bp($bp-tablet)
      padding-top: 40px
  
  &-list 
    display: flex 
    justify-content: space-between 
    align-items: flex-start
    margin-bottom: 90px 
    
    &__data 
      max-width: 488px 
      width: 100% 
      text-align: center
    
    &__right 
      flex-grow: 1 
      display: flex 
      justify-content: center
    
    &__desc 
      max-width: 361px
      width: 100% 
      border-radius: 34px 
      background-color: $white 
      font-size: rem(18px)
      line-height: 130%
      padding: 28px
      margin: 0 auto
      box-shadow: 0 10px 7.5px rgba(0,0,0,.160784)
    
    &__column 
      width: 50% 
      display: flex 
      justify-content: center
      flex-direction: column
    &__name 
      font-size: inherit 
      line-height: inherit
      margin-bottom: 0 
      color: $purple
    
    &__unit 
      margin-bottom: 20px 
      &:last-child 
        margin-bottom: 0
    
    
    .how__btn 
      max-width: 340px
    
    .how-list__top
      display: flex 
      flex-direction: column
      align-items: center
      align-items: center
      margin-bottom: 35px
    
    &__video
      max-width: 488px
      margin: 30px auto 
      video 
        width: 100%
      
      strong 
        display: block
        margin-top: 18px
  
  +bp(1024px)
    &-list 
      &__desc 
        margin: 0 30px
        max-width: 95%
  
  +bp($bp-medium) 
    &__btn 
      font-size: rem(24px)


  +bp($bp-tablet)
    padding-bottom: 80px
    &-list 
      display: block
      margin-bottom: 70px
      &--mobile 
        display: flex 
        flex-direction: column-reverse
      &__desc 
        margin: 0 0
        max-width: 100%
      &__column 
        width: 100%
      &__video 
        max-width: 100%
  
  +bp($bp-small2)
    &__part 
      display: block
    &-unit 
      margin: 0 auto 30px 

      &:last-child 
        margin-bottom: 0 
    
    &__help 
      margin: 25px 0 30px
      font-size: rem(24px)

  +bp($bp-small)
    padding-bottom: 50px 
    &-list 
      &__desc 
        font-size: rem(16px)

+bp($bp-small)
  .top__links
    gap: 10px !important

.company ~ .footer 
  padding: 30px 0 35px
  .footer__part 
    padding: 0 20px 
    border: none 
    text-align: center
    justify-content: center

