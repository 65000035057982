h1
	font-size: $h1-fs
	line-height: $h1-lh
	font-weight: 500
	margin: 0 0 70px

h2
	font-size: $h2-fs
	line-height: $h2-lh
	font-weight: 500
	margin: 0 0 75px
	
h3
	font-size: $h3-fs
	line-height: $h3-lh
	font-weight: 500
	margin: 0 0 70px

h4
	font-size: $h4-fs
	line-height: $h4-lh
	margin: 45px 0 25px
	font-weight: 500
	
h5
	font-size: $h5-fs
	line-height: $h5-lh
	margin: 45px 0 25px
	font-weight: 500
	
h6
	font-size: $h6-fs
	line-height: $h6-lh
	margin: 0px 0 45px
	font-weight: 500
	
ol li, 
ul li
	font-size: $p-fs
	line-height: $p-lh
	margin: 16px 0

p
	font-size: $p-fs
	line-height: $p-lh
	margin: 20px 0
	
small
	font-size: $small-fs
	line-height: $small-lh

blockquote
	font-size: rem(65px)
	color: $white 
	background-color: $baseTxt
	line-height: 126%
	border-radius: $radBlockSmall
	padding: 50px 57px 40px
	text-align: center
	margin-bottom: 80px
	font-weight: bold

.blockquote-small 
	font-size: rem(45px)
	color: $baseTxt
	background-color: $gray5
	box-shadow: $shadowBlock
	padding: 40px 30px

.numeric-list
	counter-reset: item
	li 
		position: relative
		padding-left: 27px
		font-size: inherit
		&:before 
			content: counters(item, ".") "."
			counter-increment: item
			+flex_center_b
			position: absolute
			top: 0px
			left: 0
			font-size: inherit

		+bp($bp-small)
			padding-left: 15px

strong 
	font-weight: bold
	

+bp($bp-large)
	h1 
		font-size: $h1-fs-1280 
		line-height: $h1-lh-1280

	h2 
		font-size: $h2-fs-1280 
		line-height: $h2-lh-1280
		margin-bottom: 50px

	h3 
		font-size: $h3-fs-1280 
		line-height: $h3-lh-1280
		margin-bottom: 50px

	h4 
		font-size: $h4-fs
		line-height: $h4-lh

	h5 
		font-size: $h5-fs-1280 
		line-height: $h5-lh-1280

	h6 
		font-size: $h6-fs-1280 
		line-height: $h6-lh-1280
		margin-bottom: 25px

	p, 
	ol li, 
	ul li
		font-size: $p-fs-1280 
		line-height: $p-lh-1280

	small 
		font-size: $small-fs-1280 
		line-height: $small-lh-1280

	blockquote
		font-size: rem(40px)

	.blockquote-small
		font-size: rem(35px)



+bp($bp-tablet)
	h1
		font-size: $h1-fs-768
		line-height: $h1-lh-768
		margin: $baseGutter 0
		margin: 0 0 round($baseGutter*1)
		
	h2
		font-size: $h2-fs-768
		line-height: $h2-lh-768
		margin-bottom: 40px
	h3
		font-size: $h3-fs-768
		line-height: $h3-lh-768
		margin-bottom: 40px
	
	h4
		font-size: $h4-fs-768
		line-height: $h4-lh-768
		margin: 0 0 round($baseGutter*0.7)

	h5
		font-size: $h5-fs-768
		line-height: $h5-lh-768
		margin: 0 0 round($baseGutter*0.7)
		
	h6
		font-size: $h6-fs-768
		line-height: $h6-lh-768
		margin: 0 0 round($baseGutter*0.6)

	p, 
	ol li, 
	ul li 
		font-size: $p-fs-768
		line-height: $p-lh-768

	small 
		font-size: $small-fs-768
		line-height: $small-lh-768

	blockquote
		font-size: rem(30px)
		padding: 35px
		margin-bottom: 40px

	.blockquote-small
		font-size: rem(25px)



		
@include bp($bp-small)
	h1
		font-size: $h1-fs-480
		line-height: $h1-lh-480
		margin: 0 0 $baseGutter
		
	h2
		font-size: $h2-fs-480
		line-height: $h2-lh-480
		margin: 0 0 25px
	
	h3
		font-size: $h3-fs-480
		line-height: $h3-lh-480
		margin-bottom: 25px
	
	h4
		font-size: $h4-fs-480
		line-height: $h4-lh-480
		margin: 0 0 30px
		
	h5
		font-size: $h5-fs-480
		line-height: $h5-lh-480
		margin: 0 0 30px
		
	h6
		font-size: $h6-fs-480
		line-height: $h6-lh-480
		margin: 0 0 30px
		
	p, 
	ol li, 
	ul li
		font-size: $p-fs-480
		line-height: $p-lh-480
		margin: 12px 0

	small 
		font-size: $small-fs-480
		line-height: $small-lh-480

	blockquote
		font-size: rem(22px)
		padding: 25px
		margin-bottom: 30px
		border-radius: 20px

	.blockquote-small
		font-size: rem(17px)
		padding: 25px 20px
		

