.article-unit 
	display: flex 
	flex-direction: column 
	justify-content: space-between 
	color: $baseTxt 
	background-color: $gray5 
	box-shadow: $shadowBlock
	border-radius: $radBlockSmall
	padding: 30px
	margin-bottom: 40px

	&__title 
		margin-top: 0 
		transition: $transition 
		margin-bottom: 50px

	&:hover 
		.article-unit__title 
			color: $purple

	+bp($bp-large)
		padding: 20px

		&__title 
			font-size: rem(20px)
			margin-bottom: 20px

	
		
