.article 
	background-color: $gray1
	padding-top: 26vw
	padding-bottom: 160px
	&--top 
		padding-top: 220px
	&-lapse 
		padding-top: 150px
		padding-bottom: 0 
		position: relative
		margin-bottom: -5px
		&--page 
			padding-top: 135px
		video 
			width: 100%
	&--page 
		background-color: $gray1
	&__top 
		margin-bottom: 95px
		display: flex 
		justify-content: space-between 
		align-items: flex-start
		margin-top: 25px

	h3.article__subtitle 
		margin-bottom: 85px

	h1 
		font-size: 7.6vw
		line-height: 1.2
	h3 
		margin-top: 70px
		margin-bottom: 50px

	p 
		font-size: rem(30px)



	&__text
		h6 
			margin-top: 45px 
			margin-bottom: 25px
			font-size: rem(30px)
			font-weight: 700
		ul 
			li 
				font-size: rem(30px)
				line-height: 128%

		button, mark
			font-size: rem(30px)
			display: inline-block 
			color: $white 
			background-color: $baseTxt 
			padding: 4px 20px 
			border-radius: 15px
			margin-top: 35px
			cursor: default

		mark 
			display: inline-block
			font-size: rem(30px)
			line-height: 1
			max-width: 394px
			padding: 9px 14px

		.mark-small
			font-size: rem(18px)
			padding: 4px 7px
			border-radius: 30px

		h5
			font-size: rem(30px)
			font-weight: 700
		table 
			margin: 50px 0
			tr:first-child 
				td 
					border-top: none
					padding-bottom: 25px
					padding-top: 0
			td 
				padding: 10px 15px 
				text-align: center 
				border-right: 1px solid $gray5
				border-top: 1px solid $gray5
				&:last-child 
					border-right: none 

			mark 
				margin-top: 0
		.table.table--topp-mark 
			tr:first-child
				mark
					min-height: 78px
					display: inline-flex 
					align-items: center 
					vertical-align: top

		h3 ~ .box 
			margin-top: 35px

		.btn
			color: $white
			margin-top: 25px 
			&:hover 
				color: $white


	a 
		font-weight: 700
		&:hover 
			color: $purple
	&__text 
		a 
			color: $purple
			&:hover 
				color: $orange
		.tag
			color: $white 
			&:hover 
				background-color: $orange
	.link-bor 
		font-weight: 400
		color: $baseTxt 
		&:hover 
			color: $purple
	&-location 
		margin: 0

	&__date 
		margin: 25px 0 0
		font-family: $secondaryFont
		text-align: right

	&__line 
		&--flex 
			display: flex 
			justify-content: space-between 
			align-items: flex-start
			position: relative
			margin-top: 80px

	&__text 
		ol
			counter-reset: item
			margin: 30px 0
			li 
				position: relative
				padding-left: 62px
				font-size: rem(30px)
				line-height: 128%
				margin-bottom: 30px
				&:before 
					content: counters(item, ".") " "
					counter-increment: item
					+flex_center_b
					width: 50px
					height: 48px 
					position: absolute
					top: 0px
					left: 0
					color: $white 
					background-color: $baseTxt
					border-radius: 13px
					font-size: inherit

				&:last-child 
					margin-bottom: 0

	&__line
		p, 
		ul li,
		ol li
			font-family: $secondaryFont 
		span 
			span 
				display: block
		h3 
			scroll-margin-top: 50px
		h2, .h2 
			font-size: rem(60px)
			font-weight: 900
			padding-left: 70px 
			position: relative
			margin-top: 60px
			padding-top: 30px
			margin-bottom: 40px
			scroll-margin-top: 20px
			&:before
				content: ''
				display: inline-block
				width: 54px 
				height: 54px 
				+foil 
				background-image: url('../images/star.svg')
				position: absolute 
				left: 0 
				top: 30px

		section:first-child 
			h2, .h2 
				margin-top: 0

		h3 
			position: relative 
			padding-left: 84px
			display: flex 
			align-items: center 
			flex-wrap: wrap
			span 
				display: inline-block 
				width: 70px 
				text-align: center
				padding: 0 0px
				border-radius: 13px
				font-size: rem(40px)
				color: $white 
				background-color: $baseTxt
				margin-right: 12px
				position: absolute 
				left: 0 
				top: 50% 
				transform: translateY(-50%)

			img 
				width: 150px
				margin: 0 15px 0 5px

		iframe 
			width: 100% 
			height: 750px
			margin-bottom: 0 !important

			+bp(1700px)
				height: 45vw

			+bp($bp-tablet)
				height: 78vw

			~ div 
				display: none

		img 
			margin: 60px 0 

		img + h3 
			margin-top: 30px

		ul 
			li 
				position: relative
				padding-left: 24px
				&:before 
					content: ''
					width: 14px
					height: 14px 
					position: absolute
					top: 6px
					left: 0
					+foil 
					background-image: url('../images/star2.svg')
					



	&__sidebar
		max-width: 284px
		width: 100%
		flex-shrink: 0
		margin-left: 40px

	&__text 
		width: calc(100% - 568px)
		padding-left: 49px 
		margin-right: 49px
		margin-left: 326px

	&-table 
		width: 326px
		position: absolute
		top: 0px 
		background-color: $white
		box-shadow: $shadowBlock
		padding: 28px 8px 40px 28px
		border-radius: 39px
		flex-shrink: 0 
		transition: 0.35s

		&.fixed 
			position: fixed
			top: 15px
			z-index: 50

			.article-table__wrap
				max-height: calc(100vh - 150px)
				overflow-y: auto



		&__wrap
			padding-right: 15px
			&::-webkit-scrollbar
				width: 4px
				background-color: transparent
				margin-top: 30px
				border-radius: 5px
				
			&::-webkit-scrollbar-thumb
				background-color: $gray5
				border-radius: 5px

		&__tag 
			margin: 0 0 30px 0
			color: $white 
			background-color: $baseTxt 
			width: 100% 
			border-radius: 30px
			padding: 6px 4px
			text-align: center

		ol
			counter-reset: item
			li 
				margin: 20px 0
				font-size: rem(30px)
				line-height: 128%
				position: relative
				

				a
					cursor: pointer 
					transition: color 0.35s ease-in-out
					font-size: inherit
					&:before 
						content: counters(item, ".") ". "
						counter-increment: item
						font-size: inherit 
						line-height: 1
					
					&:hover,
					&.is-active
						color: $purple

		a.is-active 
			color: $purple

	&__sl 
		margin-top: 70px 
		position: relative
		


	&__sl-name 
		margin: 0 0 50px 
		text-align: center 
	&__sl-wrap 
		background-color: $white 
		border-radius: 43px
		box-shadow: $shadowBlock
		padding: 20px 53px
		position: relative

	&__arrows 
		position: absolute 
		left: 0px 
		top: 50% 
		transform: translateY(-50%)
		width: 100%
		display: flex 
		justify-content: space-between
		z-index: 5
		padding: 0 17px

	&__arrow
		width: 0px
		height: 0px
		cursor: pointer
		&--next 
			width: 0
			height: 0
			border-style: solid
			border-width: 30px 0 30px 26px
			border-color: transparent transparent transparent $gray5
		&--prev 
			border-style: solid
			border-width: 30px 26px 30px 0
			border-color: transparent $gray5 transparent transparent

	&__data
		display: flex 
		justify-content: space-between 
		align-items: center
		margin-top: 70px
		padding: 0 30px 70px 20px
		border-bottom: 1px solid $gray5 

	&__soc-unit 
		transform: scale(0.9)
		transform-origin: 100% 50%
		margin-top: 0

	&__about 
		margin-left: calc(50% - 515px)
		width: 59%

		+bp(1770px)
			margin: 0 auto

	&-flex 
		display: flex 
		justify-content: space-around
		align-items: flex-end
		&__unit 
			display: flex
			flex-direction: column 
			align-items: center
		&__name 
			margin-bottom: 0

	&__slider 
		padding: 15px 10px

		.swiper-wrapper 
			display: flex 

		.swiper-slide 
			height: auto

	&__pitch-name
		opacity: 0 
		pointer-events: none



	+bp($bp-large)
		padding-bottom: 120px
		padding-top: 160px
		&__sidebar 
			width: 193px
			flex-shrink: 0
			margin-left: 0
			padding-top: 70px
		&__line, 
		img 
			margin-bottom: 30px
		&__line
			&--flex 
				margin-top: 30px

		p 
			font-size: rem(18px)
			line-height: 200%

		&__text
			h6 
				font-size: rem(18px)

		&__top 
			margin-bottom: 40px
			margin-top: 25px
		h3.article__subtitle 
			margin-bottom: 50px
			margin-top: 0
		h1 
			font-size: 6.8vw
			margin-bottom: 40px
		h2, .h2 
			margin-bottom: 30px
			font-size: rem(40px)
			padding-left: 50px
			margin-top: 25px
			&:before 
				top: 31px
				width: 37px 
				height: 37px

		h3 
			margin-bottom: 20px 

		h4 
			margin-bottom: 14px 
		h4+p 
			margin-top: 0

		ul 
			li 
				font-size: rem(18px)
				line-height: 200%
				&:before 
					top: 10px


		&-table 
			width: 222px
			padding: 20px 8px 20px 20px
			&__tag 
				font-size: rem(18px)
			&__text
				p 
					line-height: 1.3
			ol li 
				font-size: rem(18px)

		&__text 
			margin-left: 220px
			width: calc(100% - 458px)
			padding-left: 45px 
			margin-right: 45px

		


		h3 
			margin-top: 50px

		&__text
			h3
				padding-left: 60px
				span 
					font-size: rem(30px)
					width: 50px
			ol 
				li 
					font-size: rem(18px)
					line-height: 200%
					padding-left: 45px
					&:before 
						width: 28px 
						height: 28px
						border-radius: 5px
						top: 6px

		img 
			margin-top: 30px

		&__date 
			font-size: rem(25px)
			margin-top: 10px

		h3.article__subtitle 
			font-size: rem(40px)
		&__about 
			width: 80%

	+bp($bp-large2)
		&__sidebar 
			display: none
		&__text 
			width: calc(100% - 220px)
			margin-right: 0
		&__about 
			width: 100%



	+bp($bp-medium)
		padding-bottom: 90px
		&-lapse 
			padding-bottom: 0
			padding-top: 70px
		&__text 
			max-width: 100% 
			width: calc(100% - 220px)
		

		p 
			font-size: rem(18px)

		h3 
			margin-top: 40px
			margin-bottom: 25px

		h1 
			max-width: 100%
		&__line
			h2, .h2
				scroll-margin-top: 70px
		&-table 
			transition: none
			a 
				&:hover, 
				&.is-active 
					color: $baseTxt

			&.fixed 
				top: 75px

	+bp($bp-tablet)
		&-table 
			position: static 
			width: 100% 
			margin-bottom: 30px
			max-height: 400px
			overflow-y: auto
			&.fixed 
				position: static 
				max-height: 400px
				top: 0
		&__text 
			margin-left: 0 
			margin-right: 0 
			padding-left: 0 
			padding-right: 0
			width: 100%


		&__line 
			&--flex 
				flex-direction: column

		&__line
			&--flex 
				margin-top: 30px

		&__data
			padding-bottom: 40px 
			margin-top: 0px
		&__sl 
			margin-top: 40px
		&__sl-name
			margin-bottom: 30px
		&__arrows 
			display: none
		&__sl-wrap 
			padding: 20px
			border-radius: 25px
		


	+bp($bp-small)
		padding: 80px 0 50px
		&-lapse 
			padding-bottom: 0
			padding-top: 60px
		p 
			font-size: rem(15px)

		&__top 
			display: block
			margin-bottom: 30px
			margin-top: 0

		&__social 
			margin-top: 30px

		&__date 
			text-align: left
			margin-top: 12px

		&__line
			margin-bottom: 20px
			margin-top: 10px

		h1 
			font-size: 8vw
			margin-bottom: 25px

		h3.article__subtitle
			margin-bottom: 0px 
			font-size: rem(16px)

		&__line

			h2, .h2 
				font-size: 26px
				padding-left: 40px 
				margin-bottom: 20px
				margin-top: 0px
				padding-top: 20px
				scroll-margin-top: 60px
				&:before 
					width: 30px 
					height: 30px
					top: 17px


			h3 
				padding-left: 50px
				margin-bottom: 15px
				scroll-margin-top: 60px
				span 
					font-size: rem(20px)
					width: 40px
					border-radius: 5px

				img 
					width: 100px

			p, 
			ul li 
				line-height: 126%

		.box 
			margin: 40px 0
			padding: 25px

		&__data 
			flex-direction: column 
			align-items: center 
		&__soc-unit
			margin-top: 15px
			transform-origin: 50% 50%

		&-flex 
			display: block
			&__unit 
				width: 100% 
				margin-bottom: 20px 
				&:last-child 
					margin-bottom: 0
			&__name 
				margin-top: 15px
		&__sl-wrap 
			padding: 12px

		ul 
			li 
				font-size: rem(15px)
				&:before 
					top: 1px
					width: 12px
					height: 12px

		&-table 
			ol 
				li 
					margin: 8px 0
					font-size: rem(16px)

		&__text 
			ol 
				margin: 15px 0 
				li 
					margin-bottom: 25px
					line-height: 126%
					font-size: rem(15px)
					padding-left: 35px 
					&:before 
						top: 0
						width: 24px 
						height: 24px 

			h5 
				font-size: rem(16px)
				margin: 20px 0 15px
			button, mark 
				font-size: rem(16px)
				margin-top: 15px
				padding: 4px 10px 
				border-radius: 10px
			h6 
				margin-top: 25px
				margin-bottom: 15px
			table 
				margin: 20px 0
				td 
					padding: 12px 5px

					p 
						margin: 0

				tr:first-child 
					td 
						padding-bottom: 10px

			.btn
				margin: 20px 0 35px

			.table--topp-mark
				overflow-x: auto 
				table
					min-width: 350px
					tr 
						&:first-child
							mark 
								min-height: 55px !important
					td 
						font-size: rem(12px)





.box 
	margin: 60px 0
	padding: 50px 83px 50px 94px 
	border-radius: 43px 
	box-shadow: $shadowBlock 
	background-color: $gray6

	h2 
		margin-left: -70px
		&:first-child
			margin-top: 0

	&--small 
		padding: 30px

	&--white
		background-color: $white 
		margin: 25px 0
		mark 
			&:first-child
				margin-top: 0

	+bp($bp-large)
		padding: 25px 40px
		h2 
			margin-left: -20px
		&--small 
			padding: 25px

	+bp($bp-small)
		h2 
			padding-top: 0
			&:before 
				top: 0

.lapse-block 
	position: absolute 
	z-index: 2 
	color: $white 
	left: 50% 
	top: 55% 
	transform: translateX(-50%) translateY(-50%)
	text-align: center
	max-width: 567px 
	width: 100%

	h3 
		margin: 0 0 15px 
	h1 
		margin-bottom: 50px 
		line-height: 1

	+bp($bp-small)
		h1 
			margin-bottom: 15px
			font-size: 14vw

.box-list-num
	ul 
		li 
			&:before 
				display: none 

	&__num 
		+flex_center_b
		min-width: 19px 
		height: 19px 
		color: $white 
		text-align: center 
		background-color: $baseTxt
		position: absolute 
		left: -7px 
		top: 4px
		line-height: 1
		border-radius: 5px
		font-size: rem(16px)

	+bp($bp-large)
		&__num
			top: 8px

	+bp($bp-small)
		&__num
			top: 2px



