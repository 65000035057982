@mixin flex_center_h
	display: flex
	justify-content: center
	
@mixin flex_center_v
	display: flex
	align-items: center
	
@mixin flex_center_b
	display: flex
	align-items: center
	justify-content: center

@mixin flex_between_center
	display: flex 
	justify-content: space-between 
	align-items: center

@mixin flex_end_center
	display: flex 
	justify-content: flex-end
	align-items: center
