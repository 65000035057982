.lattice
	display: flex 
	justify-content: space-between 

	&__sidebar 
		width: 300px
		flex-shrink: 0
		margin-right: 35px
		padding-top: 10px
	&__content
		h2 
			padding-left: 0
			&:first-child
				margin-top: 0
			+bp(1200px, 'min')
				font-size: 3.8rem

		p, 
		ul li, 
		ol li
			font-family: $secondaryFont

	&__name
		color: $gray3 
		margin: 0 0 15px 
		font-size: rem(22px)

	&__list 
		margin: 0 

	&__item 
		color: $purple 
		.link 
			font-weight: 400
			&.is-active 
				font-weight: 900

		&:hover
			.link 
				color: $orange

	+bp($bp-large2)
		&__sidebar 
			width: 200px

	+bp($bp-tablet)
		display: block 

		&__sidebar 
			width: 100%

		&__list 
			display: flex 
			margin-bottom: 30px
		&__item 
			margin: 0 25px 0 0

	+bp($bp-small)
		display: block 
		&__item 
			margin: 0 18px 0 0
			font-size: rem(14px) !important
			white-space: nowrap
		&__name
			font-size: rem(16px)
			margin-bottom: 5px

	+bp(340px)
		display: block 
		&__item 
			margin: 0 14px 0 0
			font-size: rem(12px) !important

.article--lattice 
	padding-top: 200px
	+bp($bp-medium)
		padding-top: 140px

	+bp($bp-small)
		padding-top: 90px

.thank 
	h2 
		margin-bottom: 40px
	&__btn 
		margin-top: 20px
		width: auto
		padding-left: 55px
		padding-right: 55px

	+bp($bp-small)
		h2 
			margin-bottom: 25px 
		&__btn 
			padding-left: 30px
			padding-right: 30px
			margin-top: 15px
		h6 
			margin-bottom: 15px

