html, body, div, span, applet, object, 
iframe, h1, h2, h3, h4, h5, h6, p, blockquote, 
pre, a, abbr, acronym, address, big, cite, code, del, 
dfn, em, img, ins, kbd, q, s, samp, small, strike, 
strong, sub, sup, tt, var, b, u, i, dl, dt, dd, ol, ul, li, fieldset, 
form, label, legend, table, caption, tbody, 
tfoot, thead, tr, th, td, article, aside, canvas, 
details, embed, figure, figcaption, footer, header, 
hgroup, menu, nav, output, section, summary, 
time, mark, audio, video
	margin: 0
	padding: 0
	border: 0
	font: inherit
	vertical-align: baseline

	&:focus
		outline: 0

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section
	display: block

body
	line-height: 1

ol, ul
	list-style: none

blockquote, q
	quotes: none

blockquote
	&:before, &:after
		content: ''
		content: none

q
	&:before, &:after
		content: ''
		content: none

table
	border-collapse: collapse
	border-spacing: 0

input[type=search]
	&::-webkit-search-cancel-button, 
	&::-webkit-search-decoration,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration
		-webkit-appearance: none
		-moz-appearance: none
		-webkit-appearance: none
		-moz-appearance: none
		box-sizing: content-box

textarea
	overflow: auto
	vertical-align: top
	resize: vertical


audio, canvas, video
	display: inline-block
	display: inline
	zoom: 1
	max-width: 100%


audio:not([controls])
	display: none
	height: 0


[hidden]
	display: none


html
	font-size: 100%
	-webkit-text-size-adjust: 100%
	-ms-text-size-adjust: 100%

a
	text-decoration: none
	outline: none
	color: inherit
	&:focus, &:active, &:hover
		outline: none

sup
	vertical-align: text-top

sub
	vertical-align: text-bottom

legend
	color: #000

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main
	display: block

h1,h2,h3,h4,h5,h6 
	font-weight: 700

img
	max-width: 100%
	height: auto
	border: 0
	vertical-align: middle

figure, form
	margin: 0

fieldset
	border: 0
	margin: 0
	padding: 0

legend
	border: 0
	padding: 0
	white-space: normal
	margin-left: -7px

button, input, select, textarea
	margin: 0
	vertical-align: baseline
	vertical-align: middle
	font-family: inherit
	font-size: inherit
	font-weight: inherit
	border: none
	background-color: transparent
	outline: none
	cursor: pointer

button, input
	line-height: normal

button, select
	text-transform: none

button, html input[type="button"]
	-webkit-appearance: button
	cursor: pointer
	overflow: visible

input
	&[type="reset"], &[type="submit"]
		-webkit-appearance: button
		cursor: pointer
		overflow: visible

	&[type="checkbox"], &[type="radio"]
		box-sizing: border-box
		padding: 0

	&[type="search"]
		-webkit-appearance: textfield

		&::-webkit-search-cancel-button, 
		&::-webkit-search-decoration
			-webkit-appearance: none

button[disabled], html input[disabled]
	cursor: default


button::-moz-focus-inner, input::-moz-focus-inner
	border: 0
	padding: 0

textarea
	overflow: auto
	vertical-align: top
	resize: none

table
	border-collapse: collapse
	border-spacing: 0

svg 
	max-width: 100%
	max-height: 100%


::-moz-selection, 
::selection
	text-shadow: none

.chromeframe
	margin: 0.2em 0
	background: #ccc
	color: #000
	padding: 0.2em 0