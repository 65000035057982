.tabs 
  display: flex 
  justify-content: space-between 
  align-items: flex-start

  &__list 
    width: 228px 
    flex-shrink: 0 
    margin-right: 38px
  
  &__list, 
  &__content 
    background-color: $white 
    border-radius: 40px 
    padding: 35px
  
  &__content
    flex-grow: 1

  &__item
    transition: color 0.35s ease
    font-size: rem(20px)
    margin-bottom: 25px
    cursor: pointer 
    &:last-child 
      margin-bottom: 0 
    &.is-active,
    &:hover
      color: $purple 
  
  &__block 
    display: none 
    &.is-active 
      display: block
  
  &__block-name 
    margin-top: 0
    font-size: rem(22px)
    font-weight: 700
    pointer-events: none
  
  &__block-wrap 
    font-family: $secondaryFont

    h1,h2,h3,h4,h5,h6 
      font-weight: 700
    p, 
    ul li,
    ol li
      font-size: rem(18px) 

    ul 
      li
        padding-left: 22px 
        position: relative 

        &:before 
          content: ''
          width: 8px 
          height: 8px 
          border-radius: 50% 
          background-color: $baseTxt 
          position: absolute 
          left: 0
          top: 6px
    
    a 
      color: $purple 
      &:hover 
        color: $orange
    
    h6 
      margin-top: 50px
  
  +bp(851px, 'min')
    &__block-wrap 
      display: block !important

  +bp(850px)
    display: block
    &__content 
      padding: 8px 0

    &__list
      display: none 
    
    &__block
      display: block !important 
      border-bottom: 1px solid $gray9

      &:last-child 
        border: none
    
    &__block-wrap 
      display: none 
      padding: 0 35px

      &.is-active 
        display: block
        padding-bottom: 20px
    
    &__block-name 
      pointer-events: auto
      position: relative
      padding: 20px 35px 
      margin-bottom: 0 
      cursor: pointer
      &:after 
        content: ''
        width: 13px 
        height: 8px
        position: absolute 
        right: 25px 
        top: 33px 
        background-repeat: no-repeat
        background-size: cover 
        background-position: center center 
        background-image: url('../images/icons/arrow-bottom.svg')
        transition: transform 0.35s ease
      
      &.is-active 
        &:after 
          transform: rotate(180deg)
  
  +bp($bp-small)
    &__content
      border-radius: 30px
    &__block-name, 
    &__block-wrap 
      padding-left: 20px
      padding-right: 20px
    &__block-wrap 
      p, 
      ol li, 
      ul li 
        font-size: rem(16px) 
      h6 
        margin-top: 20px
        margin-bottom: 10px
