.pitch 
	padding: 90px 0 100px

	&__block 
		display: flex 
		flex-direction: column 
		justify-content: space-between
		font-family: $secondaryFont

		p 
			max-width: 90%

		.desc-dark 
			p 
				max-width: 100%

	&__btn 
		margin-top: 60px

	&__text
		padding-top: 15px

	&__top 
		margin-top: 100px
	&__button 
		margin-top: 30px

	+bp($bp-tablet)
		padding: 80px 0

		&__part 
			display: block

		&__block 
			p 
				max-width: 100%
				margin-top: 0
			&:first-child
				margin-bottom: 35px

		&__btn 
			margin-top: 20px


	+bp($bp-small2)
		padding: 45px 0 55px
		&__text
			padding-top: 0
		&__btn
			margin-top: 10px
		&__top
			margin-top: 30px
		&__block 
			&:first-child
				margin-bottom: 25px

	

.type 
	padding: 90px 0 100px

	&-name
		margin-bottom: 50px
		font-size: rem(52px)
		font-weight: 500
		font-family: $baseFont
		&--small 
			margin-bottom: 25px
			font-size: rem(60px)

		&--medium
			font-size: rem(65px)

		&--top 
			margin-top: 40px 
			margin-bottom: 0
			line-height: 115%
		&--small-xs
			font-size: rem(52px)
		span 
			display: inline
			vertical-align: middle

		img 
			display: inline-block 
			vertical-align: middle
			margin-left: 18px
			margin-top: -3px


	&__content
		max-width: 896px 
		width: 100% 
		margin: 0 auto

	+bp($bp-large)
		padding-top: 70px
		&-name
			&--small
				font-size: rem(50px)

			&--medium
				font-size: rem(55px)
			&--small-xs
				font-size: rem(42px)

	+bp($bp-large2)
		&-name 
			&--icon 
				font-size: rem(36px)


	+bp($bp-tablet)
		&-name
			font-size: rem(40px)
			margin-bottom: 30px

			&--top 
				margin-top: 25px
				margin-bottom: 0

			&--small-xs
				padding-top: 4vw


	+bp($bp-small)
		padding: 45px 0 50px
		&-name
			font-size: rem(30px)
			&--small 
				margin-bottom: 20px
			&--icon 
				font-size: rem(22px)
				img 
					max-width: 30px
					margin-left: 10px

			&--medium
				font-size: rem(25px)
			&--small-xs
				font-size: rem(18px)

.faq 
	padding: 50px 0 120px

	+bp($bp-medium)
		padding: 0 0 90px

	+bp($bp-small)
		padding: 20px 0 60px

.types
	+bp($bp-small)
		&--top 
			padding-top: 30px

.help 
	padding: 85px 0 
	&__block 
		max-width: 926px
		margin: 0 auto

		+bp($bp-large)
			max-width: 626px

	+bp($bp-small)
		padding: 45px 0 5px