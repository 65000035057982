.services
	display: flex 
	flex-direction: column 
	background-color: $gray1
	padding: 72px 36px 30px
	border-radius: $radBlock

	&__image
		padding: 0 30px
		margin-bottom: 60px

		&--small 
			width: 160px 
			height: 160px 
			margin-left: auto 
			margin-right: auto 
			padding: 0
			margin-top: 35px
			img 
				max-height: 100%

		&--md 
			width: 173px 
			height: 173px 
			margin-left: auto 
			margin-right: auto 
			padding: 0
			margin-top: 35px
			margin-bottom: 35px
			img 
				max-height: 100%

	&__text 
		margin-top: 40px
		font-size: rem(34px)
		line-height: 126%
		text-align: center
		padding: 0 30px

		&--large 
			margin-bottom: 60px
			font-size: rem(40px)

	.data-list 
		margin-bottom: 25px !important

	&__start 
		margin-top: auto
		margin-bottom: 30px
		&--top 
			margin-top: auto

	&__price
		text-align: center
		display: flex 
		align-items: flex-end
		justify-content: center
		// span
		// 	display: none !important
		&.type-name
			margin: 30px 0
			span
				display: block
		&--show 
			span 
				display: block !important


	&__name
		font-size: rem(30px)
		margin-top: 0


	&__month 
		font-size: rem(30px)
		margin-left: 15px
		line-height: 1 
		padding-bottom: 9px
		+bp($bp-small)
			font-size: rem(14px)
			margin-left: 8px
			padding-bottom: 4px

	&__bottom-text 
		text-align: center
		

	+bp($bp-large)
		padding: 56px 35px
		&__image,
		&__text 
			padding-left: 0 
			padding-right: 0

		&__text
			font-size: rem(24px)
			margin-top: 30px
			max-width: 434px
			margin-left: auto 
			margin-right: auto

			&--large 
				font-size: rem(30px)
				
		&__image 
			margin-top: 0
			margin-bottom: 25px

			&--md 
				margin-bottom: 35px

		&__bottom-text 
			margin: 10px 0 -20px
			font-size: rem(24px)
			font-weight: 500


	+bp($bp-medium)
		padding: 40px 25px 30px

		&__image
			margin-bottom: 25px

		&__bottom-text 
			margin: 0
			font-size: rem(18px)

	+bp($bp-tablet)
		border-radius: $radBlockTab

	+bp($bp-small2)
		margin-bottom: 40px
		border-radius: $radBlockMob
		&:last-child
			margin-bottom: 0

	+bp($bp-small)
		&__text 
			font-size: rem(20px)
			margin-top: 22px

			&--large 
				margin-bottom: 35px

		&__image
			margin-top: 0

			&--md 
				width: 140px
				height: 140px
				margin-bottom: 20px

.title-top-m
	margin: 90px 0 0

	+bp($bp-medium)
		margin: 60px 0 0

	+bp($bp-small)
		margin: 40px 0 0