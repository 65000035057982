.faq-item 
	overflow: hidden
	margin-bottom: 25px
	&.is-active 
		.faq-item__top
			border-radius: 15px 15px 0 0
			&:before 
				opacity: 0
		.faq-item__desc
			height: auto
			padding-top: 35px 
			padding-bottom: 35px
			+bp($bp-small)
				padding-top: 20px
				padding-bottom: 20px
	&__top 
		position: relative 
		padding: 16px 20px
		background-color: $gray9
		border-radius: 15px
		cursor: pointer
		padding-right: 40px
		&:after 
			content: ''
			width: 17px
			height: 4px
			background-color: $baseTxt 
			+center('v')
			right: 25px
			transition: 0.3s
		&:before 
			content: ''
			width: 4px
			height: 17px
			background-color: $baseTxt 
			+center('v')
			right: 31px
	&__name
		p 
			margin: 0
	&__desc 
		height: 0 
		overflow: hidden
		transform-origin: 0 0
		border-radius: 0 0 15px 15px
		background-color: $white
		transition: 0.4s ease
		padding-left: 20px
		padding-right: 20px
		font-family: $secondaryFont
		&.is-active
			height: auto
		p
			margin: 0 
		span 
			display: block
			margin: 0 0 10px 0
			&:last-child 
				margin-bottom: 0

	+bp($bp-small)
		margin-bottom: 12px
		&__top
			padding-right: 30px
			&:after 
				content: ''
				width: 14px
				height: 2px
				right: 12px
			&:before 
				content: ''
				width: 2px
				height: 14px
				right: 18px
