.top
	padding: 170px 0 60px
	background-color: $gray1

	&__subtitle 
		font-size: rem(150px)
		margin-bottom: 5px
		line-height: 1

		&--small 
			font-size: rem(120px)

		&--large 
			font-size: rem(166px)

		&--presentaion
			max-width: 1148px
			line-height: 126%
		
		&--top 
			margin-top: 50px


	&__about-text 
		max-width: 1237px
		margin-bottom: 0 
		font-size: rem(62px)
		margin-top: 30px

		&--full 
			max-width: 100% !important
		
		&--top 
			margin-top: 40px

			+bp($bp-small)
				margin-top: 25px
				margin-bottom: -10px

	&__title
		display: inline-block 
		padding: 20px 30px
		font-size: 30px
		color: $white 
		background-color: $baseTxt
		border-radius: 15px
		margin-bottom: 0
	
	&__title-cen 
		text-align: center 
		margin-top: 0
	
	&__title-h4 
		margin-bottom: 0
		max-width: 1110px 
		margin-left: auto 
		margin-right: auto

	&--about 
		padding-top: 240px

		// &--small-lg
		// 	font-size: 14.5vw

		// &--small
		// 	font-size: 13.7vw

		// &--small-xs
		// 	font-size: 13vw

		// &--small-xs-2
		// 	font-size: 11vw

	// &--about 
	// 	padding: 22vw 0 9vw

	&--webinar 
		// padding-bottom: 60px
		h2 
			margin-bottom: 0 
	
	&--accel 
		padding-bottom: 90px

	&__line
		display: flex 
		align-items: center
		justify-content: space-between
	
	&__logo 
		width: 165px
		margin-left: 30px
		transform: translateY(-12px)

	&__logo-name
		display: block
		margin-bottom: 5px

	&__name-c 
		text-align: center
		margin-bottom: 60px
	&__desc
		margin: 30px 0 0

	&__webinar-name 
		max-width: 1449px

	&__webinar-name
		margin-top: 40px
	
	&__decor 
		padding-left: 95px
		position: relative
		max-width: 1200px
		margin-top: 24px

		h4 
			margin: 0

		&:before 
			content: ''
			position: absolute
			top: 0
			left: 0 
			width: 72px
			height: 72px 
			+foil 
			background-image: url('..//static/images/alert.png')

	&__b 
		margin-top: 90px
		margin-bottom: 0

		&--small 
			margin-top: 78px
	
	&__block-titles
		text-align: center

		h5 
			font-size: rem(30px)
			margin: 0 0 45px 0

			+bp($bp-tablet)
				font-size: rem(20px)
			
			+bp($bp-small)
				font-size: rem(15px)
				margin-bottom: 20px
	
	&__item 
		margin-bottom: 30px
		margin-top: 0

		+bp($bp-small)
			margin-bottom: 20px
			margin-top: 0 !important
	
	&__btn 
		max-width: 740px
		margin-left: auto 
		margin-right: auto
		margin-bottom: 50px
		&:last-child 
			margin-bottom: 0
		
		+bp($bp-small)
			margin-bottom: 38px


	+bp($bp-large)
		// display: flex 
		// flex-direction: column 
		// justify-content: flex-end
		// padding-top: 20px
		// padding-bottom: 120px
		// height: 800px

		&--main 
			height: auto 
			padding-top: 170px

		&--webinar 
			padding-bottom: 60px

		&__subtitle
			font-size: rem(138px)
			&--small 
				font-size: rem(100px)
			&--large 
				font-size: rem(136px)

			&--presentaion
				max-width: 1048px

		// &__title
		// 	font-size: 18vw
		// 	&--small 
		// 		font-size: 14.4vw
		// 	&--small-xs
		// 		font-size: 13vw
		// 	&--small-xs-2
		// 		font-size: 11vw
		// 	&--small-lg
		// 		font-size: 16.5vw

		&__about-text 
			max-width: 1037px
			font-size: rem(52px)

		&__logo
			text-align: right
			img 
				width: 120px

		&__webinar-name 
			max-width: 1149px

			+bp($bp-small)
				margin-top: 20px
				padding-top: 0 !important

	+bp($bp-large2)
		// height: 600px
		// padding-bottom: 90px
		// &--main 
		// 	height: auto
		&__subtitle
			font-size: 10vw
			&--small 
				font-size: 8vw
		&__about-text 
			font-size: rem(40px)

	+bp($bp-tablet)
		// display: flex 
		// align-items: center
		// justify-content: center
		// padding-top: 150px 
		// padding-bottom: 150px
		// min-height: 85vh
		// height: auto
		&__logo
			img 
				width: 95px
		&__name-c 
			margin-bottom: 40px
		&__about-text 
			font-size: rem(30px)
		&__title-h4 
			margin-top: 30px
		&--mobile
			min-height: 0
			padding-bottom: 80px
		&--irs 
			padding-bottom: 0

	+bp($bp-small2)
		&__line
			position: relative
		&__logo 
			position: absolute 
			right: 0
		&__subtitle 
			&--top 
				margin-top: 20px
		
		&__decor 
			padding-left: 42px 
			&:before 
				width: 36px 
				height: 36px
		
		&__b 
			margin-top: 40px 
			&--small 
				margin-top: 34px

	+bp($bp-small)
		padding: 90px 0 40px

		&--accel 
			padding-bottom: 60px
		
		&--irs 
			padding-bottom: 0

		&__name-c 
			margin-bottom: 25px

		// &--about 
		// 	padding-top: 140px
		// &--mobile
		// 	padding-bottom: 50px
		// &--webinar 
		// 	padding-bottom: 30px

		&__logo 
			position: absolute 
			right: 0
			top: -30px

			img 
				width: 60px

		&__logo-name
			font-size: rem(9px)
			max-width: 60px
			margin-left: auto

		&__about-text 
			font-size: rem(23px)
			line-height: 1.2

		&__title
			font-size: rem(18px)
			padding: 12px 18px
			border-radius: 10px
			line-height: 1.2
			// &--small-xs
			// 	font-size: 17vw

.hero 
	text-align: center 
	&__title 
		max-width: 1010px
		margin: 0 auto 45px 
		font-size: rem(60px)
		font-weight: 700

		span 
			color: $purple 

	
	&__main-title
		max-width: 1210px
		font-size: rem(30px)
		margin: 0 auto 
		font-family: $secondaryFont

		+bp($bp-medium)
			font-size: rem(22px)
	
	&__btn 
		margin-top: 60px 
		max-width: 689px 
		width: 100%
		&--bot 
			margin-bottom: 0
	
	&__name 
		// margin-top: 100px 
		// font-size: rem(32px)
		max-width: 700px 
		margin: 100px auto 35px 
		font-weight: 500

		&.type-name 
			margin-bottom: 35px

		&--default 
			margin-top: 0
			font-weight: 500
		
		&--center 
			max-width: 1050px 

	&__grid 
		max-width: 1210px 
		margin: 90px auto 0
		width: 100% 
		display: grid 
		grid-template-columns: repeat(2, 1fr)
		grid-gap: 50px 40px
		text-align: left 
	
	&__item-top 
		display: flex 
		align-items: center 
	
	&__item-icon 
		width: 44px 
		height: 44px 
		margin-right: 21px
		flex-shrink: 0
	
	&__item-name 
		font-size: 1.85rem
		margin: 0 
		font-weight: 700 
		color: $black 
	
	&__text 
		// font-size: rem(15px)
		// margin-top: rem(21px)
		// line-height: 1.3
		margin-bottom: 0
		font-family: $secondaryFont
		p 
			margin-bottom: 0 
	
	&__tx 
		max-width: 1210px
		margin-left: auto 
		margin-right: auto
	
	&__subtitle
		margin-top: 26px 
		font-size: rem(18px)
		text-align: center
		font-family: $secondaryFont

		+bp($bp-medium)
			margin-top: 20px
			font-size: rem(16px)
	
	.team-unit 
		margin-bottom: 0
		// .btn 
		// 	height: 78px 
		// 	font-size: rem(32px)
		// 	border-radius: 20px
		// 	box-shadow: 0 10px 15px rgba(0,0,0,.3)

	.faq-list
		text-align: left
	
	+bp($bp-medium)
		&__title
			font-size: rem(40px)
		&__grid 
			display: block
		
		&__item 
			margin-bottom: 35px 

			&:last-child 
				margin-bottom: 0

		&__name 
			margin-bottom: 35px
	
	+bp($bp-small2)
		&__title 
			font-size: rem(30px)
			margin-bottom: 27px 
		
		&__name 
			font-size: rem(24px)
			margin: 50px 0 20px
			&--default
				margin-top: 0
			&.type-name 
				margin-bottom: 20px 
				font-size: rem(24px)
		
		&__btn 
			font-size: 20px 
			margin: 30px 0 45px
			&--bot 
				margin-bottom: 0
		
		&__item-icon 
			margin-right: 12px 
			width: 32px 
			height: 32px
		
		&__item 
			border-radius: 30px
		
		&__item-name 
			font-size: rem(18px)
		
		&__text 
			margin-top: 12px
		
		&__grid 
			margin-top: 0
		
		.team-unit 
			margin-bottom: 35px 
			&:last-child 
				margin-bottom: 0

		&__subtitle
			margin-bottom: 0


.data__title 
	margin-top: 80px

	&--top 
		margin-top: 100px
	
	+bp($bp-small)
		margin-top: 60px

.data-section
	padding-top: 5px

.data__top-btn
	max-width: 787px
	margin-left: auto 
	margin-right: auto

.data__top-title
	margin-bottom: 15px

.data__faq--center
	margin-left: auto 
	margin-right: auto
	padding-bottom: 50px

