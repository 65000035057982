.data-block
	display: flex 
	flex-direction: column 
	background-color: $baseTxt 
	color: $white 
	border-radius: 40px
	padding: 38px 33px
	&--gray 
		color: $baseTxt 
		background-color: $gray5
	&--gr-light 
		color: $baseTxt 
		background-color: $gray1
	&--white 
		color: $baseTxt
		background-color: $white
	&--min 
		min-height: 278px
	&__btn 
		margin-top: auto
	&--btn 
		padding: 38px 22px 20px
	&__name
		line-height: 126%
	&--title 
		.type-name
			max-width: 1100px

	&__center 
		text-align: center
		padding-bottom: 35px
		padding-left: 10px
		padding-right: 10px
	&__btn
		margin-top: 35px
		&--top 
			margin-top: 40px
			max-width: 930px
			margin-left: auto 
			margin-right: auto

	+bp($bp-tablet)
		margin-bottom: 20px
		&__btn 
			margin-top: 30px

	+bp($bp-small)
		border-radius: 20px
		padding: 25px 20px
		&--min 
			min-height: 0
		&__center 
			padding: 0

.data-list
	margin-bottom: 28px
	&:last-child 
		margin-bottom: 0 
	li 
		padding-left: 40px
		margin-top: 28px
		position: relative
		font-size: rem(22px)
		line-height: 126% 
		font-family: $secondaryFont
		&:before 
			content: ''
			width: 24px
			height: 25px
			position: absolute
			left: 0
			top: -2px
			+foil
			background-image: url('../images/it.png')
	&--large 
		li
			font-size: rem(27px)
	&--green 
		li 
			&:before 
				width: 26px
				height: 26px
				background-image: url('../images/list-item.png')
	&--top 
		li:first-child 
			margin-top: 0

	+bp($bp-large)
		li
			font-size: rem(18px)
		&--large 
			li
				font-size: rem(24px)

	+bp($bp-small)
		li
			font-size: rem(14px)
			margin-top: 14px
		&--green 
			li 
				&:before 
					width: 22px
					height: 22px
					top: -2px

	&--stars
		li 
			&:before 
				background-image: url('../images/star3.png')
				width: 15px
				height: 15px
				top: 3px

.desc-text--top
	margin-top: 0 !important
