.depiction 
	background-color: $white 
	padding: 29px 0

	&--about 
		background-color: $gray1
		padding: 54px 0 110px

	&__top-title
		margin: 0 
		font-size: rem(80px)

		img 
			vertical-align: top
			margin-right: 17px

		+bp($bp-large)
			max-width: 1100px
		
		+bp($bp-medium)
			font-size: 3.75rem

			img 
				width: 55px 
				height: 55px
		
		+bp($bp-small)
			font-size: 2rem

			img 
				width: 30px 
				height: 30px
				margin-right: 10px
	
	&-desc 
		display: flex 
		align-items: flex-end

		&__photo 
			flex-shrink: 0 
			margin-right: 53px
		
		&__desc 
			max-width: 675px
			margin-bottom: -8px

		+bp($bp-tablet)
			display: block 

			&__desc 
				max-width: 100% 
				margin-top: 0 
			
			&__photo
				margin-right: 0 
				margin-bottom: 30px
	
	&__block 
		margin-top: 100px 
	
	&__title 
		margin-bottom: 50px
	
	&__btn 
		display: flex 
		flex-direction: column 
		align-items: center
		margin-top: 55px 

		.btn 
			max-width: 689px 
			width: 100% 

			+bp(1680px, 'min')
				font-size: rem(40px)

		small 
			font-size: rem(14px)
			margin-top: 14px
	
	+bp($bp-tablet)
		&__block 
			margin-top: 70px 
		
		&__title 
			margin-bottom: 35px
	
	+bp($bp-small)
		&--about
			padding: 30px 0 50px
		&__block 
			margin-top: 50px 
		
		&__title 
			margin-bottom: 25px
		
		&__btn 
			margin-top: 25px
			text-align: center 

			small 
				font-size: rem(12px)
		
		&__title 
			padding-right: 75px

.text-large 
	p
		font-size: rem(24px)
		font-family: $secondaryFont
		margin-top: 0

		&:last-child 
			margin-bottom: 0

		+bp($bp-tablet)
			font-size: rem(18px)
		
		+bp($bp-small)
			font-size: rem(16px)
	
.webinar-inner
	max-width: 1207px 
	margin: 0 auto

	+bp(1760px, 'min')
		max-width: 1450px

.reviews-slider
	max-width: 858px 
	padding: 0 60px
	width: 100% 
	margin: 0 auto
	position: relative
	
	&__wrapper 
		overflow: visible
	
	.swiper-slide 
		opacity: 0 !important
	
	.swiper-slide-active 
		opacity: 1 !important
	
	.person 
		margin-top: 0
	
	+bp($bp-small)
		padding: 0 

		&__nav
			display: flex
			width: 66px 
			justify-content: space-between 
			position: absolute 
			top: -60px 
			right: 0

.slider-arrow 
	width: 40px 
	height: 40px
	border-radius: 50% 
	background-color: $white 
	display: flex 
	align-items: center 
	justify-content: center 
	cursor: pointer
	position: absolute 
	top: 47% 
	left: -60px
	transition: transform 0.35s ease 

	&--next 
		left: auto 
		right: -60px
	
	+bp($bp-medium, 'min')
	
		&:hover 
			transform: translateX(-5px)

		&--next:hover 
			transform: translateX(5px)
	svg 
		fill: $gray1 
		width: 22px
	
	&.swiper-button-disabled
		cursor: default 
		pointer-events: none 
		opacity: 0.6
	
	+bp($bp-small)
		width: 30px 
		height: 30px
		position: static 
		transform: none !important

		svg 
			width: 18px

.partners-slider
	overflow: visible
	.swiper-wrapper
		align-items: center
	.swiper-slide 
		display: inline-block 
		width: auto
	
	+bp($bp-small)
		margin-top: -20px
	
.partners__nav
	width: 90px 
	display: none 
	justify-content: space-between 
	position: absolute 
	top: -100px 
	right: 0

	.slider-arrow 
		position: static
	
	+bp(1250px)
		display: flex
	
	+bp($bp-small)
		width: 66px 
		top: -36px

.scool-list--white--dark
	border-radius: 40px 
	color: $white 
	padding: 29px 54px 29px 33px 
	background-color: $gray8
	margin: 40px 0

	+bp($bp-small)
		margin: 25px 0
		border-radius: 20px 
		padding: 25px 20px

	.scool-list__item
		padding-left: 60px 
		font-size: rem(24px)

		+bp($bp-tablet)
			font-size: 1.125rem
		
		+bp($bp-small)
			font-size: 1rem
			padding-left: 40px

		img 
			top: 2px

.desk.bg-gray
	position: relative 
	z-index: 2



